import React, { useEffect, useState } from "react";
import BuyboxChartContext from "./BuyboxChartContext";
const BuyBoxChartContextProvider = ({ children }) => {

  const [buyboxChartArray, setBuyboxChartArray] = useState([]);

  return (
    <BuyboxChartContext.Provider value={{ buyboxChartArray, setBuyboxChartArray }}>
      {children}
    </BuyboxChartContext.Provider>
  )

}

export default BuyBoxChartContextProvider;