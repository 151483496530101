import React from 'react'
import './Integration.css';
const Integration = () => {
  return (
    <div className='integration_main'>
      <h1>Integration</h1>
    </div>
  )
}

export default Integration;