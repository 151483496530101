import React, { useState, useEffect, useContext } from 'react';
import './Analytics.css';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../../Toolbar/Breadcrumbs';
import tableStyles from '../../Sales/Revenue/TableStyles';
import SelectedMarketplaceContext from '../../../Context/SelectedMarketplaceContext';
import LocationSelect from '../../Dashboard/LocationSelect/LocationSelect';
import ContactTableLoader from '../../Loaders/ContactTableLoader/ContactTableLoader';
import DataTable from 'react-data-table-component';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';

const Analytics = () => {
  const { t } = useTranslation();
  const { selectedMarketplace } = useContext(SelectedMarketplaceContext);

  const authToken = sessionStorage.getItem('authToken');
  const analytics_URL = `${t("baseUrl")}/zumi/api/priceanalytics`;

  const [analyticsLoader, setAnalyticsLoader] = useState(true);
  const [analyticsData, setAnalyticsData] = useState([]);
  const [priceMatch, setPriceMatch] = useState(false);

  const [priceAnalyticsFilters, setPriceAnalyticsFilters] = useState({
    marketplace: selectedMarketplace,
    min_price: '',
    max_price: '',
    competitorType: '',
    priceMatch: priceMatch
  });

  const handlePriceMatch = () => {
    const newPriceMatch = !priceMatch;
    setPriceMatch(newPriceMatch);
    setPriceAnalyticsFilters(prevFilters => ({ ...prevFilters, priceMatch: newPriceMatch }));
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setPriceAnalyticsFilters(prevFilters => ({ ...prevFilters, [name]: value }));
  };

  const columns = [
    {
      name: t("sku"),
      selector: row => row.sku,
      width: "150px",
    },
    {
      name: t("productInfo"),
      selector: row => row.product,
      width: "280px",
    },
    {
      name: t("currencyCode"),
      selector: row => row.currencyCode,
    },
    {
      name: t("amazonPrice"),
      selector: row => row.amazon_price,
    },
    {
      name: t("ebayPrice"),
      selector: row => row.ebay_price,
    },
    {
      name: t("shopifyPrice"),
      selector: row => row.shopify_price,
    },
    {
      name: t("competitor"),
      selector: row => row.competitor_price,
    },
  ];

  const fetchData = async (filters = null) => {
    setAnalyticsLoader(true);
    const url = new URL(analytics_URL);
    const method = filters ? 'POST' : 'GET'; // Choose method based on presence of filters

    try {
      const response = await fetch(url, {
        method: method,
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json'
        },
        body: filters ? JSON.stringify(filters) : undefined // Include body only if POST
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      setAnalyticsData(result);
    } catch (error) {
      console.log(error);
    } finally {
      setAnalyticsLoader(false);
    }
  };

  // Fetch data when component mounts with no filters
  useEffect(() => {
    if (authToken) {
      fetchData(); // Call API without filters
    }
  }, [authToken]);

  const handleSubmit = () => {
    setAnalyticsLoader(true); // Show loader while fetching
    fetchData(priceAnalyticsFilters); // Call API with filters
  };

  return (
    <div className='revenue_main'>
      <Breadcrumbs />
      <div className="priceLocationMain_cont ">
        <LocationSelect />
        <div className="priceAnalyticsMinMax_cont">
          <TextField
            type='number'
            style={{ marginRight: "10px" }}
            inputProps={{ autoComplete: 'off' }}
            id="outlined-basic"
            label="Min"
            variant="outlined"
            name="min_price"
            value={priceAnalyticsFilters.min_price}
            onChange={handleChange}
          />
          <TextField
            type='number'
            inputProps={{ autoComplete: 'off' }}
            id="outlined-basic"
            label="Max"
            variant="outlined"
            name="max_price"
            value={priceAnalyticsFilters.max_price}
            onChange={handleChange}
          />
        </div>
        <div className="priceCompare_cont">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">{t('priceCompare')}</InputLabel>
            <Select
              inputProps={{ autoComplete: 'off' }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="competitorType"
              name="competitorType"
              value={priceAnalyticsFilters.competitorType}
              onChange={handleChange}
            >
              <MenuItem value='lowestPrice'>{t('lowestPrice')}</MenuItem>
              <MenuItem value='overpriced'>{t('overpriced')}</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="priceMatch_cont">
          <div className="priceMatchHead_cont">
            <p className='priceMismatchHead'>{t('priceMismatch')}</p>
          </div>
          <div className="priceMatchBtn_cont">
            <Checkbox checked={priceMatch} onChange={handlePriceMatch} />
          </div>
        </div>
        <div className="priceAnalyticsSubmit_cont">
          <button className='filterSubmitBtn' onClick={handleSubmit}>{t('submit')}</button>
        </div>
        <div className='priceAnalyticsSearch_cont'>
          <TextField
            className="priceAnalyticsSearch"
            id="outlined-basic"
            label={t('search')}
            variant="outlined"
          />
        </div>
      </div>
      <div className="revenueTableMain_cont">
        <div className='revenueTable_cont'>
          {analyticsLoader ? (
            <ContactTableLoader />
          ) : (
            <DataTable
              columns={columns}
              data={analyticsData}
              customStyles={tableStyles}
              pagination
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Analytics;
