import React from 'react';
import './ConnectAccount.css';
import { useTranslation } from 'react-i18next';


import { Link, Outlet } from 'react-router-dom';
import Breadcrumbs from '../../Toolbar/Breadcrumbs';

const ConnectAccount = () => {
  const { t } = useTranslation();


  return (
    <div className='connectMain_cont'>
      <Breadcrumbs />
      <div className="connect_cont">
        <h3 style={{ color: "rgb(52, 71, 103)", marginBottom: "10px" }}>{t('connectAccountMainHead')}</h3>
        <p className='smallPara'>{t('connectAccountPara1')}</p>

        <div className="connectSub1_cont">
          <h4>{t('connectContactSubHead')}</h4>
          <p className='smallPara'>{t('connectAccountPara2')}</p>
          <p className='smallPara'>{t('connectAccountPara3')}</p>

        </div>
        <div className="connectSub2_cont">
          <p className='smallPara'>{t('connectAccountPara4')} <a href="">{t('backupNow')}</a></p>
          <p className='smallPara'>{t('connectAccountPara5')} <a href="">{t('learnMore')}</a></p>
        </div>

        <div className="connectBtn_cont">
          <Link to="/home/whatsapp/connect-account/connect-step1" className='connectNowBtn'>{t('connect')}</Link>
        </div>


      </div>
    </div>
  )
}

export default ConnectAccount;