import { consumerData } from "../ConsumerData"
export const consumerStyle = {
    data: {
      labels: consumerData.map((data) => data.date),
      datasets: [{
        label: "Users",
        data: consumerData.map((data) => data.userNum),
        // borderColor: 'rgb(14, 113, 195)',
        borderColor: '#987D9A',

        // backgroundColor: [
        //   'rgba(255, 26, 104, 0.2)',
        //   'rgba(54, 162, 235, 0.2)',
        //   'rgba(255, 206, 86, 0.2)',
        //   'rgba(75, 192, 192, 0.2)',
        //   'rgba(153, 102, 255, 0.2)',
        //   'rgba(255, 159, 64, 0.2)',
        //   'rgba(0, 0, 0, 0.2)',
        //   '#BFF6C3'
        // ],

      }]
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          ticks: {
            stepSize: 10, // Increase by 20 units
            // maxTicksLimit: 5 // Maximum number of ticks to display
          },
          grid: {
            // display: false,
            beginAtZero: true
          },
          // ticks: {
          //   color: '#fff'
          // }
        },
        x: {
          grid: {
            display: false,
          },
          // ticks: {
          //   color: '#fff'
          // },
        }
      },
      plugins: {
        legend: {
          display: false,
        }
      },
      elements: {
        line: {
          tension: 0.3  // Change the tension here
        }
      }
    }
  }