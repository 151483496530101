import React from 'react';
import './StartImport.css';
import { Link } from 'react-router-dom';
import LaunchIcon from '@mui/icons-material/Launch';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../../../Toolbar/Breadcrumbs';

const StartImport = () => {
  const { t } = useTranslation();

  const authToken = sessionStorage.getItem("authToken");

  // H A N D L E   S A M P L E   C S V   F I L E   D O W N L O A D
  const handleDownload = async () => {
    try {
      const response = await fetch(`${t("baseUrl")}/zumi/sampleContactFile.csv`,
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${authToken}`, // Add the Authorization header
          },
        }
      );
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'sampleContactFile.csv');        // file name
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url); // Clean up
    } catch (error) {
      console.error('Error downloading the file', error);
    }
  };

  return (
    <div className='startImportMain_cont'>
      <Breadcrumbs />
      <div className="importContactHeading_cont">
        <h3 style={{ textAlign: "center" }}>{t('startImportMainHead')}</h3>
      </div>
      <div className="startImportMain">
        <div className="importOneMain">
          <Link to='/home/contacts/import-contact/start-import/submit-contact' className='submitContactLink'>
            <div className="importCardOne_cont startImportCard" >
              <div className="importImgCont">
                <img className='importContactImg' src="//static.hsappstatic.net/ui-images/static-2.677/optimized/import-file.svg" alt="" />
              </div>
              <h4 className='importContactH5'>{t('startImportCardHead')}</h4>
              <p className='importContactPara'>{t('startImportCardPara')}</p>

            </div></Link>
          {/* <div className="importCardOne_cont startImportCard" >
            <div className="importImgCont">
              <img className='importContactImg' src="//static.hsappstatic.net/ui-images/static-2.677/optimized/import-file.svg" alt="" />
            </div>
            <h4 className='importContactH5'>Import file from computer</h4>
            <p className='importContactPara'>Upload any CSV, XLS, or XLSX files with contact, company, deal, ticket, and product information.</p>

          </div> */}
        </div>
      </div>

      <div className="startImportContactHelp_cont">
        <div className="startImportHelpCont1">
          <h4>{t('startHelpHead')}</h4>
        </div>
        <div className="startImportHelpCont2">
          <div className="helpAnchorCont">
            <Link className='helpAnchor' to="/home/contacts/import-contact/start-import/import-guide">
              {t('startHelpLink1')}
              <LaunchIcon className='launchIcon' style={{ width: "18px", height: "18px", marginLeft: "5px" }} />
            </Link>
          </div>
          <div className="helpAnchorCont">
            {/* <Link className='helpAnchor' to="/contacts/import-contact/start-import/download-spreadsheet"> */}
            <Link className='helpAnchor' onClick={handleDownload}>
              {t('startHelpLink2')}
              <LaunchIcon className='launchIcon' style={{ width: "18px", height: "18px", marginLeft: "5px" }} />
            </Link>
          </div>
          <div className="helpAnchorCont">
            <Link className='helpAnchor' to="/home/contacts/import-contact/start-import/FAQs">{t('startHelpLink3')}</Link>
          </div>
        </div>

      </div>

    </div>
  )
}

export default StartImport;