
export const dashboardData = [
  {
    id: 1,
    time: "Mon",
    totalSales: 370,
    unitsSold: 40,
    profit: 2,
    unfulfilled: 12,
    AdSpend: 76,
    buyer: 46,
    inventory: 240,
  },
  {
    id: 2,
    time: "Tue",
    totalSales: 410,
    unitsSold: 70,
    profit: 7,
    unfulfilled: 15,
    AdSpend: 23,
    buyer: 58,
    inventory: 400,
  },
  {
    id: 3,
    time: "Wed",
    totalSales: 230,
    unitsSold: 90,
    profit: 11,
    unfulfilled: 9,
    AdSpend: 93,
    buyer: 76,
    inventory: 330,
  },
  {
    id: 4,
    time: "Thu",
    totalSales: 540,
    unitsSold: 80,
    profit: 8,
    unfulfilled: 6,
    AdSpend: 87,
    buyer: 62,
    inventory: 590,
  },
  {
    id: 5,
    time: "Fri",
    totalSales: 170,
    unitsSold: 65,
    profit: 5,
    unfulfilled: 18,
    AdSpend: 45,
    buyer: 91,
    inventory: 410,
  },
  {
    id: 6,
    time: "Sat",
    totalSales: 220,
    unitsSold: 95,
    profit: 8,
    unfulfilled: 17,
    AdSpend: 34,
    buyer: 87,
    inventory: 550,
  },
  {
    id: 7,
    time: "Sun",
    totalSales: 540,
    unitsSold: 105,
    profit: 6,
    unfulfilled: 11,
    AdSpend: 60,
    buyer: 49,
    inventory: 610,
  },

]

export const totalSales = dashboardData.reduce((accumulator, currentValue) => accumulator + currentValue.totalSales, 0);
export const unitsSold = dashboardData.reduce((accumulator, currentValue) => accumulator + currentValue.unitsSold, 0);
export const profit = dashboardData.reduce((accumulator, currentValue) => accumulator + currentValue.profit, 0);
export const unfulfilled = dashboardData.reduce((accumulator, currentValue) => accumulator + currentValue.unfulfilled, 0);
export const adSpend = dashboardData.reduce((accumulator, currentValue) => accumulator + currentValue.AdSpend, 0);
export const buyer = dashboardData.reduce((accumulator, currentValue) => accumulator + currentValue.buyer, 0);
export const inventory = dashboardData.reduce((accumulator, currentValue) => accumulator + currentValue.inventory, 0)
