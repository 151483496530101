import React, { useState } from "react";
import SelectedMarketplaceContext from "./SelectedMarketplaceContext";

const SelectedMarketplaceContextProvider = ({ children }) => {

  const [selectedMarketplace, setSelectedMarketplace] = useState(null);

  return (
    <SelectedMarketplaceContext.Provider value={{ selectedMarketplace, setSelectedMarketplace }}>
      {children}
    </SelectedMarketplaceContext.Provider>
  )
}

export default SelectedMarketplaceContextProvider;