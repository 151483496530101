import React, { useEffect, useState } from 'react';
import './Carousel.css'; // Make sure to create this CSS file
import CarouselImg1 from '../../../../assets/carouselSvg1.svg';
import CarouselImg2 from '../../../../assets/carouselSvg2.svg';
import CarouselImg3 from '../../../../assets/carouselSvg3.svg';
import CarouselImg4 from '../../../../assets/carouselImg1.jpg';
import CarouselImg5 from '../../../../assets/carouselImg2.jpg';
import CarouselImg6 from '../../../../assets/carouselImg3.jpg';
import CarouselImg7 from '../../../../assets/carouselImg4.jpg';


const slides = [
  // {
  //   id: 1,
  //   name: 'Slide 1',
  //   image: CarouselImg1,
  //   backgroundColor: '#ff6b6b'
  // },
  // {
  //   id: 2,
  //   name: 'Slide 2',
  //   image: CarouselImg2,
  //   backgroundColor: '#4ecdc4'
  // },
  // {
  //   id: 3,
  //   name: 'Slide 3',
  //   image: CarouselImg3,
  // },
  {
    id: 1,
    name: 'Slide 1',
    image: CarouselImg4,
    backgroundColor: '#ff6b6b'
  },
  {
    id: 2,
    name: 'Slide 2',
    image: CarouselImg5,
    backgroundColor: '#4ecdc4'
  },
  {
    id: 3,
    name: 'Slide 3',
    image: CarouselImg6,
  },
  {
    id: 4,
    name: 'Slide 3',
    image: CarouselImg7,
  },
];

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="carousel">
      {slides.map((slide, index) => (
        <div
          key={slide.id}
          className={`slide ${index === currentIndex ? 'active' : ''}`}
        // style={{ backgroundColor: slide.backgroundColor }}
        >
          <img className='carouselImg' src={slide.image} alt="" />
        </div>
      ))}
    </div>
  );
};

export default Carousel;
