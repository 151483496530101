import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18next/i18n'
import MarketplaceContextProvider from './Context/MarketplaceContextProvider';
import SelectedMarketplaceContextProvider from './Context/SelectedMarketplaceContextProvider';
import ContactsContextProvider from './Context/ContactsContextProvider';
import SegmentContextProvider from './Context/SegmentContextProvider';
import TemplateContextProvider from './Context/TemplateContextProvider';
import TokenContextProvider from './Context/TokenContextProvider';
import TotalSalesContextProvider from './Context/TotalSalesContextProvider';
import UnitsSoldContextProvider from './Context/UnitsSoldContextProvider';
import ProfitContextProvider from './Context/ProfitContextProvider';
import RevenueContextProvider from './Context/RevenueContextProvider';
import ProfitChartContextProvider from './Context/ProfitChartContextProvider';
import OrdersChartContextProvider from './Context/OrdersChartContextProvider';
import BuyBoxChartContextProvider from './Context/BuyboxChartContextProvider';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <MarketplaceContextProvider>
      <SelectedMarketplaceContextProvider>
        <ContactsContextProvider>
          <SegmentContextProvider>
            <TemplateContextProvider>
              <TokenContextProvider>
                <TotalSalesContextProvider>
                  <UnitsSoldContextProvider>
                    <ProfitContextProvider>
                      <RevenueContextProvider>
                        <ProfitChartContextProvider>
                          <OrdersChartContextProvider>
                            <BuyBoxChartContextProvider>
                              <App />
                            </BuyBoxChartContextProvider>
                          </OrdersChartContextProvider>
                        </ProfitChartContextProvider>
                      </RevenueContextProvider>
                    </ProfitContextProvider>
                  </UnitsSoldContextProvider>
                </TotalSalesContextProvider>
              </TokenContextProvider>
            </TemplateContextProvider>
          </SegmentContextProvider>
        </ContactsContextProvider>
      </SelectedMarketplaceContextProvider>
    </MarketplaceContextProvider>
  </React.StrictMode>

);

reportWebVitals();
