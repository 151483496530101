import React, { useEffect, useState } from "react";
import OrdersChartContext from "./OrdersChartContext";
const OrdersChartContextProvider = ({ children }) => {

  const [ordersChartArray, setOrdersChartArray] = useState([]);

  return (
    <OrdersChartContext.Provider value={{ ordersChartArray, setOrdersChartArray }}>
      {children}
    </OrdersChartContext.Provider>
  )

}

export default OrdersChartContextProvider;