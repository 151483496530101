import React, { useState, useContext, useEffect } from 'react'
import './Segment.css'

import Breadcrumbs from '../../Toolbar/Breadcrumbs';
import tableStyles from '../../Sales/Revenue/TableStyles';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DeleteIcon from '@mui/icons-material/Delete';

import CreateSegment from './CreateSegment/CreateSegment';
import ViewRecipients from '../../WhatsApp/CreateTemplate/MessageHistory/ViewRecipients/ViewRecipients';
import ContactTableLoader from '../../Loaders/ContactTableLoader/ContactTableLoader';

import DataTable from 'react-data-table-component';
import TextField from '@mui/material/TextField';

import { useTranslation } from 'react-i18next';
import SegmentContext from '../../../Context/SegmentContext';

const createSegmentStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 950,
  height: 480,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: "15px"
  // margin: 'auto',
  // width: '100%',
  // height: '100%',
  // border: '1px solid blue',
};

const recipientStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 950,
  height: 480,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: "15px"
  // margin: 'auto',
  // width: '100%',
  // height: '100%',
  // border: '1px solid blue',
};

const Segment = () => {

  const { t } = useTranslation();
  // const { tokenFromContext } = useContext(TokenContext);
  const { segmentArray, setSegmentArray } = useContext(SegmentContext);

  const [segmentData, setSegmentData] = useState([]);
  const [segmentContacts, setSegmentContacts] = useState([])
  const [segmentLoader, setSegmentLoader] = useState(true);

  const segment_URL = `${t("baseUrl")}/zumi/api/getsegment`;
  const delete_URL = `${t("baseUrl")}/segment/delete`;
  const [deleteId, setDeleteId] = useState();

  const [sortSegmentDirection, setSortSegmentDirection] = useState('desc');
  const [searchQuery, setSearchQuery] = useState('');

  const [newSegmentOpen, setNewSegmentOpen] = React.useState(false);
  const handleNewSegmentOpen = () => setNewSegmentOpen(true);
  const handleNewSegmentClose = () => setNewSegmentOpen(false);

  const [recipientsOpen, setRecipientsOpen] = React.useState(false);
  const handleRecipientsOpen = () => setRecipientsOpen(true);
  const handleRecipientsClose = () => setRecipientsOpen(false);
  const authToken = sessionStorage.getItem('authToken');

  const [sortItem, setSortItem] = React.useState([
    { id: 1, value: "desc", name: t('latest') },
    { id: 2, value: "asc", name: t('oldest') },
  ]);

  const columns = [
    {
      name: t('segName'),
      // selector: row => row.segmentName,
      selector: row => <div className="sentSegName" onClick={() => fetchRecipients(row.segmentId)}>{row.segmentName}</div>,
    },
    {
      name: t('description'),
      selector: row => row.description
    },
    {
      name: t('createdDate'),
      // selector: row => row.created_date,
      selector: row => {
        const date = new Date(row.created_date);
        return date.toLocaleString('en-US', {
          // weekday: 'long',
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          hour12: true
        });
      },
    },
    {
      name: t('actions'),
      selector: row => row.actions = <div className="actionsButtonCont">
        <div className="deleteBtn_cont" onClick={() => { deleteItem(row.messageId) }}>
          <DeleteIcon className='deleteIcon' style={{ height: '20px', width: '20px' }}></DeleteIcon>
        </div>
      </div>,
    }

  ];


  // F E T C H   S E G M E N T S   D A T A 

  useEffect(() => {
    if (authToken) {
      const fetchData = async () => {
        try {
          const response = await fetch(segment_URL, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${authToken}`, // Add the Authorization header
              'Content-Type': 'application/json' // Set content type if necessary
            }
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          setSegmentData(result);
        } catch (error) {
          console.log(error)
        } finally {
          setSegmentLoader(false);
        }

      };
      fetchData();
    }
  }, [authToken]);

  // S E T T I N G   S E G M E N T S   C O N T E X T

  useEffect(() => {
    if (segmentData) {
      setSegmentArray(segmentData);
    }
  }, [segmentData, setSegmentArray]);


  // S O R T   M E S S A G E  H I S T O R Y   D A T A 

  const handleSegmentSort = (column, sortSegmentDirection) => {
    setSortSegmentDirection(sortSegmentDirection);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  // S O R T   A N D   S E A R C H   S E G M E N T   D A T A 

  const filteredAndSortedTemplateData = React.useMemo(() => {
    const filteredData = segmentData.filter(item =>
      item.segmentName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return filteredData.slice().sort((a, b) => {
      if (sortSegmentDirection === 'asc') {
        return new Date(a.created_date) - new Date(b.created_date);
      } else {
        return new Date(b.created_date) - new Date(a.created_date);
      }
    });
  }, [segmentData, sortSegmentDirection, searchQuery]);


  // F U N C T I O N   T O   G E T   S E G M E N T   C O N T A C T S  

  const fetchRecipients = async (id) => {
    const recipients_URL = `${t("baseUrl")}/zumi/api/getcontact/${id}`;
    try {
      const response = await fetch(recipients_URL, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
      setSegmentContacts(result);
    } catch (error) {
      console.log(error)
    }
    handleRecipientsOpen();
  };

  // D E L E T E  T E M P L A T E   I T E M

  const deleteItem = async (id) => {
    try {
      const response = await fetch(`${delete_URL}?id=${id}`, {
        method: 'POST',
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      // Update state after successful deletion
      setDeleteId(id);
      setSegmentData(segmentData.filter(item => item.id !== id));
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };



  return (
    <div className='segmentMain_cont'>
      <Breadcrumbs />
      <div className="locationMain_cont">
        {/* <LocationSelect /> */}
        <div className='searchInput_cont '>
          <Box
            component="form"
            sx={{
              '& > :not(style)': { width: '100%', borderRadius: "" },
              '& .MuiOutlinedInput-root': {
                borderRadius: '',
              },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField className="searchTemplate" onChange={handleSearch} id="outlined-basic" label={t('search')} variant="outlined" />
          </Box>
        </div>
        <div className="sortInput_cont">
          <Box sx={{ minWidth: 120, }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">{t('sort')}</InputLabel>
              <Select
                style={{ borderRadius: "", border: 'none' }}
                className='sortSelect'
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={sortSegmentDirection}
                label="Sort"
                onChange={e => handleSegmentSort(null, e.target.value)}
              >{
                  sortItem.map((item) => {
                    return (
                      <MenuItem key={item.id} value={item.value}>{item.name}</MenuItem>
                    )
                  })
                }
              </Select>
            </FormControl>
          </Box>
        </div>
      </div>
      <div className="newSegmentBtn_cont">
        <button className='newTemplateBtn' onClick={handleNewSegmentOpen}>
          {t('createSegment')}
        </button>
        <Modal
          className='templateStyleMobile'
          open={newSegmentOpen}
          onClose={handleNewSegmentOpen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={createSegmentStyle}>
            <CreateSegment closeModal={handleNewSegmentClose} />
          </Box>
        </Modal>

        <Modal
          className='templateStyleMobile'
          open={recipientsOpen}
          onClose={handleRecipientsOpen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={recipientStyle}>
            <ViewRecipients segmentContacts={segmentContacts} closeModal={handleRecipientsClose} />
          </Box>
        </Modal>
      </div>
      <div className="presentSegmentMain_cont">
        <div className="presentSegments_cont">

          {segmentLoader ? (
            <ContactTableLoader />
          ) : (
            <DataTable
              columns={columns}
              customStyles={tableStyles}
              data={filteredAndSortedTemplateData}
              defaultSortField="created_date"
              defaultSortAsc={sortSegmentDirection === 'asc'}
              pagination
            >
            </DataTable>
          )}

          {/* <DataTable
            columns={columns}
            // data={sortedSegmentData}
            data={segmentData}
            customStyles={tableStyles}
            // defaultSortField="dateCreated"
            // defaultSortAsc={sortSegmentDirection === 'asc'}
            pagination
          >
          </DataTable> */}


        </div>
      </div>
    </div>
  )
}

export default Segment;