import React, { useState } from 'react';
import './ViewRecipients.css'
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';

const ViewRecipients = ({ segmentContacts, closeModal }) => {

  const { t } = useTranslation();
  const [recipients, setRecipients] = useState(segmentContacts);

  const columns = [
    {
      name: t("id"),
      selector: (row, index) => index + 1, // Display incremental ID
      style: { width: '60px' },
    },
    {
      name: t("userName"),
      selector: row => row.username,
      style: { width: '60px' },
    },
    {
      name: t("email"),
      selector: row => row.email,
      style: { width: '60px' },
    },
    {
      name: t("phone"),
      selector: row => row.phone,
      style: { width: '60px' },
    },
    {
      name: t("country"),
      selector: row => row.country,
      style: { width: '60px' },
    },
    {
      name: t("city"),
      selector: row => row.city,
      style: { width: '60px' },
    },
    {
      name: t("zipCode"),
      selector: row => row.zip_code,
      style: { width: '60px' },
    },
  ];

  const sentMessagesStyles = {
    headRow: {
      style: {
        position: "sticky",
        top: 0,
        color: "black",
        width: "100%",
        // border: "1px solid green",
        fontSize: "1rem",
        lineHeight: "27px",
      },
    },
    headCells: {
      style: {
        padding: "10px",
        textAlign: "left",
        width: "100%",
      },
    },
    cells: {
      style: {
        padding: "10px",
        fontSize: "0.9rem",
        fontFamily: "Poppins, Montserrat",
        color: "black",
        lineHeight: "25px",
      },
    }
  }

  return (
    <div className='viewRecipientsMain_cont'>
      <div className="newSegmentHeader">
        <div className="templateMessage_cont">
          <span className='templateMessage'>
            {t("recipients")}
          </span>

        </div>
        <div className="closeBtn_cont" role="button" aria-label="Close" onClick={closeModal}>
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-x crossSvg" viewBox="0 0 16 16">
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
          </svg>
        </div>
      </div>
      <div className="viewRecipients_cont">
        <div className="viewRecipientsTable_cont">
          <DataTable
            columns={columns}
            customStyles={sentMessagesStyles}
            data={recipients}
            pagination
          >
          </DataTable>
        </div>
      </div>

    </div>
  )
}

export default ViewRecipients;