
export const consumerData = [
  {
    userNum: 5,
    date: "1-July",
  },
  {
    userNum: 6,
    date: "2-July",

  },
  {
    userNum: 2,
    date: "3-July",

  },
  {
    userNum: 9,
    date: "4-July",

  },
  {
    userNum: 7,
    date: "5-July",
    
  },
  {
    userNum: 13,
    date: "6-July",

  },
  {
    userNum: 15,
    date: "7-July",

  },
  {
    userNum: 11,
    date: "8-July",
  },
  {
    userNum: 8,
    date: "9-July",
  },
  {
    userNum: 6,
    date: "10-July",
  },
  {
    userNum: 18,
    date: "11-July",
  },
  {
    userNum: 14,
    date: "12-July",
  },
  {
    userNum: 15,
    date: "13-July",
  },
  {
    userNum: 21,
    date: "14-July",
  },
  {
    userNum: 16,
    date: "15-July",
  },
  {
    userNum: 18,
    date: "16-July",
  },
  {
    userNum: 20,
    date: "17-July",
  },
  {
    userNum: 25,
    date: "18-July",
  },
  {
    userNum: 27,
    date: "19-July",
  },
  {
    userNum: 16,
    date: "20-July",
  },
  {
    userNum: 16,
    date: "21-July",
  },
  {
    userNum: 15,
    date: "22-July",
  },
  {
    userNum: 18,
    date: "23-July",
  },
  {
    userNum: 28,
    date: "24-July",
  },
  {
    userNum: 22,
    date: "25-July",
  },
  {
    userNum: 23,
    date: "26-July",
  },
  {
    userNum: 26,
    date: "27-July",
  },
  {
    userNum: 24,
    date: "28-July",
  },
  {
    userNum: 19,
    date: "29-July",
  },
  {
    userNum: 20,
    date: "30-July",
  },
  
  
]

// export const totalSales = dashboardData.reduce((accumulator, currentValue) => accumulator + currentValue.totalSales, 0);
// export const unitsSold = dashboardData.reduce((accumulator, currentValue) => accumulator + currentValue.unitsSold, 0);
// export const profit = dashboardData.reduce((accumulator, currentValue) => accumulator + currentValue.profit,0);
// export const unfulfilled = dashboardData.reduce((accumulator, currentValue) => accumulator + currentValue.unfulfilled, 0);
// export const adSpend = dashboardData.reduce((accumulator, currentValue) => accumulator + currentValue.AdSpend, 0);
// export const buyer = dashboardData.reduce((accumulator, currentValue) => accumulator + currentValue.buyer, 0);
// export const inventory = dashboardData.reduce((accumulator, currentValue) => accumulator + currentValue.inventory, 0)



