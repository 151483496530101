import React from 'react'
import './Shipping.css'
const Shipping = () => {
  return (
    <div className='shipping_main'>
      <h1>Shipping</h1>
    </div>
  )
}

export default Shipping;