import React, { useEffect, useState, useContext } from 'react';
import './Login.css';
// import LoginSvg from './assets/loginSvg.svg';
import LoginSvg from '../../../assets/loginSvg.svg'
import { TextField } from '@mui/material';
import Alert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';
import SelectedMarketplaceContext from '../../../Context/SelectedMarketplaceContext';

const textFieldStyle = {
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      // borderColor: 'rgb(23, 193, 232)',
      borderRadius: "15px",
      // borderWidth: "1.5px",
    },
    '&:hover fieldset': {
      // borderColor: 'black',
      borderRadius: "15px",
    },
    '&.Mui-focused fieldset': {
      // borderColor: 'black',
      borderRadius: "15px",
    },
  },
}

const Login = () => {
  const navigate = useNavigate();
  // const { setTokenFromContext } = useContext(TokenContext);
  const { setSelectedMarketplace } = useContext(SelectedMarketplaceContext);
  const [incorrectAlert, setIncorrectAlert] = useState(false);
  const [emptyUserPassAlert, setEmptyUserPassAlert] = useState(false);
  const [loginSuccessAlert, setLoginSuccessAlert] = useState(false);
  const [loginLoader, setLoginLoader] = useState(false);

  const [loginDetails, setLoginDetails] = useState({
    username: "",
    password: "",
  })

  const handleChange = (e) => {
    setLoginDetails({ ...loginDetails, [e.target.name]: e.target.value });
  }

  // F U N C T I O N   F O R   L O G I N

  const handleLogin = async (e) => {
    e.preventDefault();
    const login_URL = 'http://3.25.175.167:8081/zumi/api/auth/login';

    // Set loader
    setLoginLoader(true);
    if (loginDetails.username === "" || loginDetails.password === "") {
      setEmptyUserPassAlert(true);
      setLoginLoader(false);
      return;
    }


    const loginDetailsJson = JSON.stringify(loginDetails);
    try {
      // Send POST request
      const response = await fetch(login_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: loginDetailsJson,
      });

      // Check if response is not OK
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Parse the JSON response
      const data = await response.json();
      // setTokenFromContext(data.token);
      setLoginSuccessAlert(true);

      // Navigate to the main page
      navigate('/home/dashboard');
      sessionStorage.setItem('authToken', data.token);
      sessionStorage.setItem('userId', data.userId);
      sessionStorage.setItem('activeMarketplaceId', data.channelId);
      // setSelectedMarketplace(data.channelId);
      console.log(data);

    }
    catch (err) {
      // Handle any errors
      console.error('Error posting data:', err);
      setIncorrectAlert(true);
      setEmptyUserPassAlert(false);
    } finally {
      // Hide loader after the request completes, regardless of success or failure
      setLoginLoader(false);
    }
  };

  useEffect(() => {
    if (loginDetails.username === "" || loginDetails.password === "") {
      setIncorrectAlert(false);
    }
  }, [loginDetails]);


  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle login logic here
    console.log("Email:", email, "Password:", password);
  };



  return (
    // <div className='loginMain_cont'>
    //   <div className="loginHeading_cont">
    //     <h2 className='loginHeading'>Sign In</h2>
    //   </div>
    //   <div className="loginSub_cont">
    //     <div className="loginSub_cont1">
    //       <img src={LoginSvg} className='loginSvg' alt="" />
    //     </div>
    //     <div className="loginSub_cont2">
    //       <div className="loginForm_cont">
    //         <div>
    //           <div className="formInput_cont">
    //             <label htmlFor="username" className="welcomeFormLabel">Email <span className="asterisk">*</span></label>
    //             <TextField
    //               type='email'
    //               className='welcomeFormInput'
    //               InputLabelProps={{ style: { display: 'none' } }}
    //               inputProps={{ autoComplete: 'off' }}
    //               name="username"
    //               id="username"
    //               placeholder="Enter your email"
    //               variant="outlined"
    //               sx={textFieldStyle}
    //               value={loginDetails.username}
    //               onChange={handleChange}
    //             />
    //           </div>
    //           <div className="formInput_cont">
    //             <label htmlFor="password" className="welcomeFormLabel">Password  <span className="asterisk">*</span></label>
    //             <TextField
    //               type='password'
    //               className='welcomeFormInput'
    //               InputLabelProps={{ style: { display: 'none' } }}
    //               inputProps={{ autoComplete: 'off' }}
    //               name="password"
    //               id="password"
    //               placeholder="Enter password"
    //               variant="outlined"
    //               sx={textFieldStyle}
    //               value={loginDetails.password}
    //               onChange={handleChange}
    //             />
    //           </div>
    //           <div className="formInput_cont loginBtn_cont">
    //             <button className='btnFill' onClick={handleLogin}>Sign In</button>
    //             {loginLoader && <div className="loader"></div>}
    //           </div>
    //         </div>
    //         <div className="loginAlert_cont">
    //           {emptyUserPassAlert &&
    //             <Alert severity="warning" className='loginAlert' id='incorrectAlert'>Enter username and password.</Alert>
    //           }
    //           {incorrectAlert &&
    //             <Alert severity="error" className='loginAlert' id='incorrectAlert'>Incorrect username or password.</Alert>
    //           }
    //           {loginSuccessAlert &&
    //             <Alert severity="success" className='loginAlert' id='incorrectAlert'>Login Successfull</Alert>
    //           }
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>


    <div className="loginMain_cont centerElm">
      <div className="loginCardMain_cont">
        <div className="loginWelcomeHead_cont">
          <h2>Welcome Back!</h2>
          <p>Please enter your details.</p>
        </div>

        <div className="loginForm_cont">
          {/* <div> */}
          <div className="formInput_cont">
            <label htmlFor="username" className="welcomeFormLabel">Email <span className="asterisk">*</span></label>
            <TextField
              type='email'
              className='welcomeFormInput'
              InputLabelProps={{ style: { display: 'none' } }}
              inputProps={{ autoComplete: 'off' }}
              name="username"
              id="username"
              placeholder="Enter your email"
              variant="outlined"
              sx={textFieldStyle}
              value={loginDetails.username}
              onChange={handleChange}
            />
          </div>
          <div className="formInput_cont">
            <label htmlFor="password" className="welcomeFormLabel">Password  <span className="asterisk">*</span></label>
            <TextField
              type='password'
              className='welcomeFormInput'
              InputLabelProps={{ style: { display: 'none' } }}
              inputProps={{ autoComplete: 'off' }}
              name="password"
              id="password"
              placeholder="Enter password"
              variant="outlined"
              sx={textFieldStyle}
              value={loginDetails.password}
              onChange={handleChange}
            />
          </div>
          <div className="formInput_cont loginBtn_cont">
            <button className='btnFill' onClick={handleLogin}>Sign In</button>
            {loginLoader && <div className="loader"></div>}
          </div>
          {/* </div> */}
          <div className="loginAlert_cont">
            {emptyUserPassAlert &&
              <Alert severity="warning" className='loginAlert' id='incorrectAlert'>Enter username and password.</Alert>
            }
            {incorrectAlert &&
              <Alert severity="error" className='loginAlert' id='incorrectAlert'>Incorrect username or password.</Alert>
            }
            {loginSuccessAlert &&
              <Alert severity="success" className='loginAlert' id='incorrectAlert'>Login Successfull</Alert>
            }
          </div>
        </div>

      </div>
    </div>

  )
}

export default Login;