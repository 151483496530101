import React, { useState, useEffect, useContext } from 'react';
import './whatsapp.css'
import DataTable from 'react-data-table-component';
// import NewTemplate from '../NewTemplate/NewTemplate'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import NoDataComponent from './NoData/NoData';
import ContactTableLoader from '../Loaders/ContactTableLoader/ContactTableLoader';
import Breadcrumbs from '../Toolbar/Breadcrumbs';

import CreateTemplate from './CreateTemplate/CreateTemplate';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import { baseUrl } from '../../Properties/Properties';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TemplateContext from '../../Context/TemplateContext';
import SegmentContext from '../../Context/SegmentContext';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1150,
  height: 580,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: "15px"
  // margin: 'auto',
  // width: '100%',
  // height: '100%',
  // border: '1px solid blue',
};

const Whatsapp = () => {

  const { t } = useTranslation();
  const authToken = sessionStorage.getItem('authToken');
  const userId = sessionStorage.getItem('userId');
  // const { tokenFromContext } = useContext(TokenContext);
  const { templateArray, setTemplateArray } = useContext(TemplateContext);
  const { segmentArray, setSegmentArray } = useContext(SegmentContext);

  const [templateData, setTemplateData] = useState([]);
  const [segmentData, setSegmentData] = useState([]);
  const [templateLoader, setTemplateLoader] = useState(true);
  const [deleteId, setDeleteId] = useState();
  const template_URL = `${t("baseUrl")}/whatsapp/template?userID=${userId}`;
  const delete_URL = `${t("baseUrl")}/whatsapp/delete`;
  const segment_URL = `${t("baseUrl")}/zumi/api/getsegment`;

  const [sortTemplateDirection, setSortTemplateDirection] = useState('desc');
  const [searchQuery, setSearchQuery] = useState('');

  const [updateId, setUpdateId] = useState(null);
  const [broadcastTemplateId, setBroadcastTemplateId] = useState(null);

  const [open, setOpen] = React.useState(false);
  const handleOpen = (id) => setOpen(true);
  const handleClose = () => setOpen(false);

  const [broadcastOpen, setBroadcastOpen] = React.useState(false);
  const handleBroadcastOpen = (id) => setBroadcastOpen(true);
  const handleBroadcastClose = () => setBroadcastOpen(false);



  const templateTableStyles = {
    headRow: {
      style: {
        position: "sticky",
        top: 0,
        color: "black",
        color: "rgb(14, 113, 195)",
        width: "100%",
        fontSize: "1rem",
        lineHeight: "24px",
      },
    },
    headCells: {
      style: {
        // border: "1px solid blue",
        padding: "10px",
        textAlign: "left",
      },
    },
    cells: {
      style: {
        // border: "1px solid red",
        padding: "10px",
        fontSize: "0.9rem",
        color: "black",
        lineHeight: "25px",
      },
    }
  }

  const columns = [
    {
      name: t('templateName'),
      selector: row => row.templateName,
    },
    {
      name: t('category'),
      selector: row => row.category.charAt(0).toUpperCase() + row.category.slice(1).toLowerCase(),
      minWidth: "140px",
      width: "140px"
    },
    {
      name: t('status'),
      // selector: row => row.status,
      selector: row => row.status.charAt(0).toUpperCase() + row.status.slice(1).toLowerCase(),
      minWidth: "140px",
      width: "140px"
    },
    {
      name: t('language'),
      // selector: row => row.language,
      selector: row => {
        if (row.language === "en") {
          return "English";
        }
        if (row.language === "en_US") {
          return "English (US)";
        }
        if (row.language === "en_UK") {
          return "English (UK)";
        }
      },
      minWidth: "140px",
      width: "140px"
    },
    {
      name: t('dateCreated'),
      selector: row => {
        const date = new Date(row.created_DateTime);
        return date.toLocaleString('en-US', {
          // weekday: 'long',
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          hour12: true
        });
      },

    },
    {
      name: t('actions'),
      selector: row => row.actions = <div className="actionsButtonsCont">

        <div className="deleteBtn_cont" onClick={() => { deleteItem(row.messageId) }}>
          <DeleteIcon className='deleteIcon' style={{ height: '20px', width: '20px' }}></DeleteIcon>
        </div>
        {row.flag === 1 && (
          <div className="broadcastBtn_cont" onClick={() => openBroadcastTemplate(row.messageId)}>
            Broadcast
          </div>
        )}
        {/* {row.status === 'APPROVED' && (
          <div className="broadcastBtn_cont" onClick={() => openBroadcastTemplate(row.messageId)}>
            Broadcast
          </div>
        )} */}
        {/* <div className="broadcastBtn_cont" onClick={() => { openBroadcastTemplate(row.messageId) }}>
          {t('broadcast')}
        </div> */}
        {/* <div className="deleteBtn_cont" onClick={() => { deleteItem(row.messageId) }}>
          <DeleteIcon className='deleteIcon' style={{ height: '20px', width: '20px' }}></DeleteIcon>
        </div> */}
      </div>,
      style: {
        width: '120px'
      }
    },
  ];

  const [sortItem, setSortItem] = React.useState([
    { id: 1, value: "desc", name: t('latest') },
    { id: 2, value: "asc", name: t('oldest') },
  ]);

  // S E T   B R O A D C A S T   I D 

  function openBroadcastTemplate(id) {
    handleBroadcastOpen();
    setBroadcastTemplateId(id)
  }

  // D E L E T E  T E M P L A T E   I T E M

  const deleteItem = async (id) => {
    try {
      const response = await fetch(`${delete_URL}?id=${id}`, {
        method: 'POST',
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      // Update state after successful deletion
      setDeleteId(id);
      setTemplateData(templateData.filter(item => item.id !== id));
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  // F E T C H   S E G M E N T S   D A T A 

  useEffect(() => {
    if (authToken) {

      const fetchData = async () => {
        try {
          const response = await fetch(segment_URL, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${authToken}`, // Add the Authorization header
              'Content-Type': 'application/json' // Set content type if necessary
            }
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          setSegmentData(result);
        } catch (error) {
          console.log(error);
        }

      };
      fetchData();
    }
  }, []);

  // S E T T I N G   S E G M E N T   C O N T E X T 

  useEffect(() => {
    if (segmentData) {
      setSegmentArray(segmentData);
    }
  }, [segmentArray, segmentData]);


  // F E T C H   T E M P L A T E   D A T A 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(template_URL, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${authToken}`, // Add the Authorization header
            'Content-Type': 'application/json' // Set content type if necessary
          }
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        setTemplateData(result);
      } catch (error) {
        console.log(error);
      } finally {
        setTemplateLoader(false);
      }
    };
    fetchData();
  }, [deleteId]);

  // S E T T I N G   T E M P L A T E   C O N T E X T 

  useEffect(() => {
    if (templateData) {
      setTemplateArray(templateData);
    }
  }, [templateData, setTemplateArray, deleteId]);


  // S O R T   A N D   S E A R C H   T E M P L A T E   D A T A 

  const handleTemplateSort = (column, sortTemplateDirection) => {
    setSortTemplateDirection(sortTemplateDirection);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };


  const filteredAndSortedTemplateData = React.useMemo(() => {
    const filteredData = templateData.filter(item =>
      item.templateName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return filteredData.slice().sort((a, b) => {
      if (sortTemplateDirection === 'asc') {
        return new Date(a.created_DateTime) - new Date(b.created_DateTime);
      } else {
        return new Date(b.created_DateTime) - new Date(a.created_DateTime);
      }
    });
  }, [templateData, sortTemplateDirection, searchQuery]);


  return (
    <div className='whatsapp_main'>
      <Breadcrumbs />
      <div className='searchAndbtn_cont'>
        <div className='searchMain_cont'>
          <div className='searchInput_cont '>
            <Box
              component="form"
              sx={{
                '& > :not(style)': { width: '100%', borderRadius: "" },
                '& .MuiOutlinedInput-root': {
                  borderRadius: '',
                },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField className="searchTemplate" onChange={handleSearch} id="outlined-basic" label={t('search')} variant="outlined" />
            </Box>
          </div>
          <div className="sortInput_cont">
            <Box sx={{ minWidth: 120, }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">{t('sort')}</InputLabel>
                <Select
                  style={{ borderRadius: "", border: 'none' }}
                  className='sortSelect'
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={sortTemplateDirection}
                  label="Sort"
                  onChange={e => handleTemplateSort(null, e.target.value)}
                >{
                    sortItem.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.value}>{item.name}</MenuItem>
                      )
                    })
                  }
                </Select>
              </FormControl>
            </Box>
          </div>
        </div>

        <div className='btn_cont'>
          <Link to='/home/whatsapp/knowledge' className='newTemplateBtn connectBtn'>
            Help
          </Link>
          <Link to='/home/whatsapp/connect-account' className='newTemplateBtn connectBtn'>
            {t('connectAccount')}
          </Link>
          <Link to='/home/whatsapp/message-history' className='newTemplateBtn historyBtn'>
            {t('messageHistory')}
          </Link>
          <button className='newTemplateBtn ' onClick={handleOpen}>
            {t('newTemplate')}
          </button>
          <button className='newTemplateBtnMob' onClick={handleOpen}>
            {t('createNew')} <AddIcon style={{ height: "20px", width: '20px' }}></AddIcon>
          </button>
          <Modal
            className='templateStyleMobile'
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <CreateTemplate btnText="Submit" closeModal={handleClose} broadcastTemplateId={null} updateId={updateId} templateData={templateData}></CreateTemplate>
            </Box>
          </Modal>

          <Modal
            className='templateStyleMobile'
            open={broadcastOpen}
            onClose={handleBroadcastClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <CreateTemplate btnText="Broadcast" closeModal={handleBroadcastClose} broadcastTemplateId={broadcastTemplateId} templateData={templateArray}></CreateTemplate>
            </Box>
          </Modal>
        </div>
      </div>
      <div className='tableMain_cont'>
        <div className='table_cont'>
          {templateLoader ? (
            <ContactTableLoader />
          ) : (
            <DataTable
              columns={columns}
              customStyles={templateTableStyles}
              noDataComponent={<NoDataComponent />}
              data={filteredAndSortedTemplateData}
              defaultSortField="created_DateTime"
              defaultSortAsc={sortTemplateDirection === 'asc'}
              pagination
            />
          )}
        </div>
      </div>
    </div>
  )
}
export default Whatsapp;