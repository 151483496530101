import React from 'react';
import './HelpGetStart.css'
import Breadcrumbs from '../../../Toolbar/Breadcrumbs';

const HelpGetStart = () => {
  return (
    <div className='helpGetStartMain_cont'>
      <Breadcrumbs />
      <div className="helpGetStart_cont">
        Help Get Start
      </div>
    </div>
  )
}

export default HelpGetStart;