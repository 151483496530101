import React from 'react'
import './Sales.css'
import Breadcrumbs from '../Toolbar/Breadcrumbs'
const Sales = () => {
  return (
    <div className='sales_main'>
      <h1>Sales</h1>
    </div>
  )
}

export default Sales;