import React, { useContext, useState, useEffect } from 'react'
import './Consumer.css';
import Breadcrumbs from '../Toolbar/Breadcrumbs';
import tableStyles from '../Sales/Revenue/TableStyles';
import LocationSelect from '../Dashboard/LocationSelect/LocationSelect';

import DataTable from 'react-data-table-component';

import { useTranslation } from 'react-i18next';
import TokenContext from '../../Context/TokenContext';

import ConsumerChart from './ConsumerChart/ConsumerChart';
import { consumerStyle } from './ConsumerChart/ConsumerStyle';
import { baseUrl } from '../../Properties/Properties';

import { Link } from 'react-router-dom';
import ContactsContext from '../../Context/ContactsContext';
import ContactTableLoader from '../Loaders/ContactTableLoader/ContactTableLoader';

const Consumer = () => {

  const { t } = useTranslation();
  const { contactsArray, setContactsArray } = useContext(ContactsContext);
  const contact_URL = `${t("baseUrl")}/zumi/api/getcontact`;

  const [contactData, setContactData] = useState([]);
  const [contactLoader, setContactLoader] = useState(true);

  const columns = [
    {
      name: t('contactId'),
      selector: row => row.consumerId,
      width: "100px",
    },
    {
      name: t("name"),
      selector: row => row.firstName

    },
    {
      name: t('email'),
      selector: row => row.email

    },
    {
      name: t('marketplace'),
      selector: row => row.marketplaceName
    },
    {
      name: t('phone'),
      selector: row => row.phone
    },
    {
      name: t('city'),
      selector: row => row.city
    },
    {
      name: t('country'),
      selector: row => row.country,
      width: "90px"
    },
    {
      name: t('zipCode'),
      selector: row => row.zipCode,
      width: "90px"
    }
  ];

  const authToken = sessionStorage.getItem('authToken');


  // F E T C H   C O N T A C T S   D A T A 

  useEffect(() => {

    if (authToken) {
      const fetchData = async () => {
        try {
          const response = await fetch(contact_URL, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${authToken}`, // Add the Authorization header
              'Content-Type': 'application/json' // Set content type if necessary
            }
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          setContactData(result);
        } catch (error) {
          console.log(error);
        } finally {
          setContactLoader(false);
        }
      };
      fetchData();
    }

  }, [authToken]);

  // S E T T I N G   C O N T A C T S   C O N T E X T
  useEffect(() => {
    if (contactData) {
      setContactsArray(contactData);
    }
  }, [contactData, setContactsArray])


  return (
    <div className='consumer_main'>
      <Breadcrumbs />
      <div className="locationMain_cont">
        <LocationSelect />
        {/* <DateSelect /> */}
      </div>
      <div className="uploadContactBtn_cont">
        <Link to={'/home/contacts/import-contact'} className='uploadContactBtn'>{t('importContacts')}</Link>
        <Link to={'/home/contacts/segment'} className="segmentBtn">{t('viewSegments')}</Link>
      </div>
      <div className="contactTableMain_cont">

        <div className='contactTable_cont'>

          {contactLoader ? (
            <ContactTableLoader />
          ) : (
            <DataTable
              columns={columns}
              data={contactsArray}
              customStyles={tableStyles}
              pagination
            />
          )}
        </div>
      </div>

      {/* <div className="conMainChartMain_cont">
        <div className="conMainChart_cont">
          <div className="dcont_heading">
            <span>Number of Users</span>
            <h5 className="firstrowdata">
              250
            </h5>
          </div>
          <div className="conMainChart">
            <ConsumerChart consumerData={consumerStyle.data} options={consumerStyle.options} />
          </div>
          <div className="conLegendMain_cont">

          </div>
        </div>

      </div> */}


    </div>
  )
}

export default Consumer;