import React, { useEffect, useState } from 'react';
import './NewMarketplace.css';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { useTranslation } from 'react-i18next';
import { TextField } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Link } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MarketAmazon from '../../../assets/market-amazon.png';
import MarketShopify from '../../../assets/market-shopify.png';
import MarketEbay from '../../../assets/market-ebay.png';

import AmazonCard from '../../../assets/AmazonCard.png';
import ShopifyCard from '../../../assets/ShopifyCard.png';
import EbayCard from '../../../assets/EbayCard.png';

import Carousel from './Carousel/Carousel';

const textFieldStyle = {
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      // borderColor: 'rgb(23, 193, 232)',
      borderRadius: "15px",
      // borderWidth: "1.5px",
    },
    '&:hover fieldset': {
      // borderColor: 'black',
      borderRadius: "15px",
    },
    '&.Mui-focused fieldset': {
      // borderColor: 'black',
      borderRadius: "15px",
    },
  },
}

const NewMarketplace = () => {
  const { t } = useTranslation();

  const [activeStep, setActiveStep] = useState(0);
  const [mandatoryError1, setMandatoryError1] = useState("");
  const [mandatoryError2, setMandatoryError2] = useState("");
  const [loader1, setLoader1] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [newMarketplace, setNewMarketplace] = useState({
    region: "",
    marketplaceName: "",
  });

  const [connectAccountDetails, setConnectAccountDetails] = useState({
    accountName: "",
    sellerEmail: "",
  });

  const steps = [
    'Marketplace',
    'Connect Account',
    'Create PPC',
  ];

  const regions = [
    { value: "Australia", name: "Australia" },
    { value: "India", name: "India" },
  ]

  const marketplaces = [
    { value: "Amazon", name: "Amazon" },
    { value: "eBay", name: "eBay" },
    { value: "Shopify", name: "Shopify" },
  ]

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setNewMarketplace((prevData) => ({ ...prevData, [name]: value }));
  }
  const handleChange2 = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setConnectAccountDetails((prevData) => ({ ...prevData, [name]: value }));
  }

  const handleNext = (e) => {
    e.preventDefault();
    setLoader1(true);
    console.log("Marketplace: ", newMarketplace);
    setActiveStep((prev) => prev + 1);
    setLoader1(false);
  }

  const handleAuthenticate = (e) => {
    e.preventDefault();
    setLoader2(true);
    console.log("Account Details: ", connectAccountDetails);
    setLoader2(false);
  }

  const handleBack = (e) => {
    e.preventDefault();
    setActiveStep((prev) => prev - 1);
  }


  useEffect(() => {
    if (!newMarketplace.region || !newMarketplace.marketplaceName) {
      setMandatoryError1("Please fill mandatory fields.")
    }
    else {
      setMandatoryError1("");
    }
  }, [newMarketplace]);

  useEffect(() => {
    if (!connectAccountDetails.accountName || !connectAccountDetails.sellerEmail) {
      setMandatoryError2("Please fill mandatory fields.")
    }
    else {
      setMandatoryError2("");
    }
  }, [connectAccountDetails])



  return (
    <div className='newMarketMain_cont'>
      <div className="newMarketSub_cont">
        <div className="newMarketCont1">
          <div className="newMarketHead_cont">
            <h1>New Marketplace</h1>
          </div>
          {/* {newMarketplace.marketplaceName == "" && (
            <div className="newMarketCarousel_cont">
              <Carousel />
            </div>
          )}
          {newMarketplace.marketplaceName === "Amazon" && <div className="marketAmazonImg_cont">
            <img src={AmazonCard} alt="Amazon Logo" />
          </div>}
          {newMarketplace.marketplaceName === "Shopify" && <div className="marketShopifyImg_cont">
            <img src={ShopifyCard} alt="Shopify Logo" />
          </div>}
          {newMarketplace.marketplaceName === "eBay" && <div className="marketEbayImg_cont">
            <img src={EbayCard} alt="Ebay Logo" />
          </div>} */}


          <div className="newMarketCarousel_cont">
            <Carousel />
          </div>
        </div>
        <div className="newMarketCont2">

          {/* S T E P P E R */}

          <div className="stepper_cont">
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>

          {/* F I R S T   F O R M */}

          {activeStep === 0 && (
            <div className="newMarketForm_cont1">
              <div className="selectedMarketplaceName_cont">
                <h2></h2>
              </div>
              <div className="newMarketInput_cont">
                <label htmlFor="region" className="welcomeFormLabel">{t('region')}  <span className="asterisk">*</span></label>
                <Select
                  style={{ borderRadius: '15px' }}

                  className='regionSelect'
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="region"
                  value={newMarketplace.region}
                  onChange={handleChange}
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <em>Select Region</em>;
                    }
                    return selected.charAt(0).toUpperCase() + selected.slice(1).toLowerCase();
                  }}
                >
                  {regions.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.value}>{item.name}</MenuItem>
                    )
                  })}
                </Select>
              </div>


              <div className="newMarketInput_cont">
                <label htmlFor="marketplace" className="welcomeFormLabel">{t('marketplace')}  <span className="asterisk">*</span></label>
                <Select
                  style={{ borderRadius: '15px' }}
                  className='regionSelect'
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="marketplaceName"
                  value={newMarketplace.marketplaceName}
                  onChange={handleChange}
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <em>Select Marketplace</em>;
                    }
                    return selected.charAt(0).toUpperCase() + selected.slice(1).toLowerCase();
                  }}
                >

                  {marketplaces.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.value}>{item.name}</MenuItem>
                    )
                  })}
                </Select>
              </div>

              <div className="newMarketplaceBtn_cont">
                {loader1 && <div className="loader"></div>}
                <button className='btnFill' disabled={mandatoryError1} onClick={handleNext}>Next</button>
              </div>

              <div className="newMarketError_cont">
                {mandatoryError1 && (
                  <p style={{ color: 'red', fontSize: "12px", }}>{mandatoryError1}.</p>
                )}
              </div>
            </div>
          )}

          {/* S E C O N D   F O R M */}



          {activeStep === 1 && (
            <div className="newMarketForm_cont2">
              <div className="selectedMarketplaceName_cont">
                <h2>{newMarketplace.marketplaceName}</h2>
              </div>
              <div className="newMarketInput_cont">
                <label htmlFor="accountName" className="welcomeFormLabel">{t('accountName')}  <span className="asterisk">*</span></label>
                <TextField
                  className='welcomeFormInput'
                  InputLabelProps={{ style: { display: 'none' } }}
                  inputProps={{ autoComplete: 'off' }} // Disable autocomplete
                  type='text'
                  name="accountName"
                  id="outlined-basic"
                  placeholder="Enter name"
                  variant="outlined"
                  sx={textFieldStyle}
                  value={connectAccountDetails.accountName}
                  onChange={handleChange2}
                />
              </div>


              <div className="newMarketInput_cont">
                <label htmlFor="sellerEmail" className="welcomeFormLabel">{t('sellerCentralEmail')}  <span className="asterisk">*</span></label>
                <TextField
                  className='welcomeFormInput'
                  InputLabelProps={{ style: { display: 'none' } }}
                  inputProps={{ autoComplete: 'off' }}
                  type='email'
                  name="sellerEmail"
                  id="outlined-basic"
                  placeholder="Enter emal"
                  variant="outlined"
                  sx={textFieldStyle}
                  value={connectAccountDetails.sellerEmail}
                  onChange={handleChange2}
                />
              </div>

              <div className="newMarketplaceBtn_cont">
                {newMarketplace.marketplaceName == "Amazon" && (
                  <button className='btnFill' disabled={mandatoryError1} onClick={handleAuthenticate}>AuthenticateA</button>
                )}
                {newMarketplace.marketplaceName == "Shopify" && (
                  <button className='btnFill' disabled={mandatoryError1} onClick={handleAuthenticate}>AuthenticateS</button>
                )}
                {newMarketplace.marketplaceName == "eBay" && (
                  <button className='btnFill' disabled={mandatoryError1} onClick={handleAuthenticate}>AuthenticateE</button>
                )}
                {/* <button className='btnFill' onClick={handleAuthenticate}>Authenticate</button> */}
                <Link className='marketBackBtn' onClick={handleBack}><ChevronLeftIcon />Go Back</Link>
              </div>

              <div className="newMarketError_cont">
                {mandatoryError2 && (
                  <p style={{ color: 'red', fontSize: "12px", }}>{mandatoryError2}.</p>
                )}
              </div>

            </div>
          )}

        </div>
      </div>
    </div>
  )
}

export default NewMarketplace;