import React from 'react';
import './ContactFAQ.css';
import Breadcrumbs from '../../../Toolbar/Breadcrumbs';

const ContactFAQ = () => {
  return (
    <div className='contactFaqMain_cont'>
      <Breadcrumbs />
      <div className="contactFaq_cont">
        View FAQs
      </div>
    </div>
  )
}

export default ContactFAQ;