import React from 'react';
import './ConnectStep2.css';
import FacebookIcon from '@mui/icons-material/Facebook';
import Breadcrumbs from '../../../Toolbar/Breadcrumbs';
import { useTranslation } from 'react-i18next';


const ConnectStep2 = () => {
  const { t } = useTranslation();


  return (
    <div className='connectStep2Main_cont'>
      <Breadcrumbs />
      <div className="connectStepSub_Cont">
        <span className='stepSpan'>Step 2/2</span>
        <div className="connectStep2_cont">
          <h3>{t("connectStep2Head1")}</h3>
          <p>{t("connectStep2Para1")}</p>
          <div className="facebookBtn_cont">
            <a target='_blank' href="https://www.facebook.com/" className='facebookBtn'><FacebookIcon style={{ marginRight: "10px" }} />{t('continueFacebook')}</a>
          </div>
        </div>



      </div>

    </div>
  )
}

export default ConnectStep2;