import React, { useState } from "react";
import "./SideBar.css";

import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SideMoreOptions from "./SideMoreOptions";
import TableRowsIcon from "@mui/icons-material/TableRows";
import { NavLink } from "react-router-dom";
// import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import AppsIcon from "@mui/icons-material/Apps";
import TableChartIcon from "@mui/icons-material/TableChart";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import CategoryIcon from "@mui/icons-material/Category";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import PeopleIcon from "@mui/icons-material/People";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import MoneyIcon from "@mui/icons-material/Money";
import ViewStreamIcon from "@mui/icons-material/ViewStream";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import SendIcon from '@mui/icons-material/Send';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import SearchIcon from '@mui/icons-material/Search';
import StoreIcon from '@mui/icons-material/Store';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';

const SideBar = () => {
  const { t } = useTranslation();
  const [isSalesSubLinksVisible, setIsSalesSubLinksVisible] = useState(false);
  const [isProductsSubLinksVisible, setIsProductsSubLinksVisible] = useState(false);
  const [isSupplierSubLinksVisible, setIsSupplierSubLinksVisible] = useState(false);
  const [isIntegrationSubLinksVisible, setIsIntegrationSubLinksVisible] = useState(false);

  const toggleSaleSubLinks = () => {
    setIsSalesSubLinksVisible(prevState => !prevState);
  };
  const toggleProductSubLinks = () => {
    setIsProductsSubLinksVisible(prevState => !prevState);
  };
  const toggleSupplierSubLinks = () => {
    setIsSupplierSubLinksVisible(prevState => !prevState);
  };
  const toggleIntegrationSubLinks = () => {
    setIsIntegrationSubLinksVisible(prevState => !prevState);
  };

  return (
    <div className="sidebarMain_cont" id="sideBarMain">
      <div className="sideButtonsMain_cont">

        {/* D A S H B O A R D */}

        <div
          className="sideButtonLink_cont"
          id="sideButtonLink"
        >
          <NavLink
            to="/home/dashboard"
            activeclassname="active"
            className="sideNavLink"
          >
            <div className="sideButton_cont">
              <div className="buttonIcon_cont activeb">
                <TableChartIcon
                  style={{ fontSize: "20px", color: "gold" }}
                ></TableChartIcon>
              </div>
              <span className="sideButtonName">{t('dashboard')}</span>
            </div>
          </NavLink>
        </div>


        {/* S A L E S */}

        <div
          className="sideButtonLink_cont sideSaleBtn"
          id="sideButtonLink"
          onClick={toggleSaleSubLinks}
          activeclassname="active"
        >
          <div
            activeclassname="active"
            className="sideNavLink"
          >
            <div className="sideButton_cont">
              <div className="buttonIcon_cont activeb">
                <LoyaltyIcon
                  style={{ fontSize: "20px", color: "red" }}
                ></LoyaltyIcon>{" "}
              </div>
              <div className="sidebarLinksInner_cont">
                <span className="sideButtonName">{t('sales')}</span>
                <div className="sidebarLinkArrow_cont">
                  {!isSalesSubLinksVisible && (<KeyboardArrowDownIcon />)}
                  {isSalesSubLinksVisible && (<KeyboardArrowUpIcon />)}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* S A L E S   S U B L I N K S */}

        <div
          className={`salesSubLink_cont ${isSalesSubLinksVisible ? 'visible' : ''}`}
        >
          <NavLink
            to="/home/salesrevenue"
            activeclassname="active"
            className="sideSubNavLink"
            style={{ marginTop: "8px" }}
          >
            <div className="sideSubButton_cont">
              <div className="buttonIcon_cont activeb">
                <AutoGraphIcon
                  style={{ fontSize: "20px", color: "#48CFCB" }}
                ></AutoGraphIcon>{" "}
              </div>
              <span className="sideSubButtonName">{t('revenue')}</span>
            </div>
          </NavLink>
          <NavLink
            to="/home/salesprofit"
            activeclassname="active"
            className="sideSubNavLink"
          >
            <div className="sideSubButton_cont">
              <div className="buttonIcon_cont activeb">
                <AttachMoneyIcon
                  style={{ fontSize: "20px", color: "#E85C0D" }}
                ></AttachMoneyIcon>{" "}
              </div>
              <span className="sideSubButtonName">{t('profit')}</span>
            </div>
          </NavLink>
          {/* <NavLink
            to="/home/salescost"
            activeclassname="active"
            className="sideSubNavLink"
          >
            <div className="sideSubButton_cont">
              <div className="buttonIcon_cont activeb">
                <MoneyIcon
                  style={{ fontSize: "20px", color: "#00712D" }}
                ></MoneyIcon>{" "}
              </div>
              <span className="sideSubButtonName">Cost</span>
            </div>
          </NavLink> */}
          <NavLink
            to="/home/salesorders"
            activeclassname="active"
            className="sideSubNavLink"
          >
            <div className="sideSubButton_cont">
              <div className="buttonIcon_cont activeb">
                <ViewStreamIcon
                  style={{ fontSize: "20px", color: "#F9E400" }}
                ></ViewStreamIcon>{" "}
              </div>
              <span className="sideSubButtonName">{t('order')}</span>
            </div>
          </NavLink>
          <NavLink
            to="/home/salesbuybox"
            activeclassname="active"
            className="sideSubNavLink"
          >
            <div className="sideSubButton_cont">
              <div className="buttonIcon_cont activeb">
                <ShoppingBagIcon
                  style={{ fontSize: "20px", color: "#B692C2" }}
                ></ShoppingBagIcon>{" "}
              </div>
              <span className="sideSubButtonName">{t('buybox')}</span>
            </div>
          </NavLink>
        </div>

        {/* P R O D U C T S */}

        <div
          className="sideButtonLink_cont sideProductBtn"
          id="sideButtonLink"
          onClick={toggleProductSubLinks}
          activeclassname="active"
        >
          <div
            activeclassname="active"
            className="sideNavLink"
          >
            <div className="sideButton_cont">
              <div className="buttonIcon_cont activeb">
                <CategoryIcon
                  style={{ fontSize: "20px", color: "green" }}
                ></CategoryIcon>{" "}
              </div>
              <div className="sidebarLinksInner_cont">
                <span className="sideButtonName">{t('product')}</span>
                <div className="sidebarLinkArrow_cont">
                  {!isProductsSubLinksVisible && (<KeyboardArrowDownIcon />)}
                  {isProductsSubLinksVisible && (<KeyboardArrowUpIcon />)}
                  {/* <KeyboardArrowDownIcon /> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* P R O D U C T S    S U B L I N K S  */}

        <div
          className={`salesSubLink_cont ${isProductsSubLinksVisible ? 'visible' : ''}`}
        >
          <NavLink
            to="/home/product-catalogs"
            activeclassname="active"
            className="sideSubNavLink"
            style={{ marginTop: "8px" }}
          >
            <div className="sideSubButton_cont">
              <div className="buttonIcon_cont activeb">
                <AutoGraphIcon
                  style={{ fontSize: "20px", color: "#48CFCB" }}
                ></AutoGraphIcon>{" "}
              </div>
              <span className="sideSubButtonName">{t('catalogs')}</span>
            </div>
          </NavLink>
          <NavLink
            to="/home/price-analytics"
            activeclassname="active"
            className="sideSubNavLink"
          >
            <div className="sideSubButton_cont">
              <div className="buttonIcon_cont activeb">
                <AttachMoneyIcon
                  style={{ fontSize: "20px", color: "#E85C0D" }}
                ></AttachMoneyIcon>{" "}
              </div>
              <span className="sideSubButtonName">{t('analytics')}</span>
            </div>
          </NavLink>
        </div>

        {/* S U P P L I E R  */}

        <div
          className="sideButtonLink_cont"
          id="sideButtonLink"
          onClick={toggleSupplierSubLinks}
          activeclassname="active"
        >
          <div
            activeclassname="active"
            className="sideNavLink"
          >
            <div className="sideButton_cont">
              <div className="buttonIcon_cont activeb">
                <HomeRepairServiceIcon
                  style={{ fontSize: "20px", color: "brown" }}
                ></HomeRepairServiceIcon>{" "}
              </div>
              <div className="sidebarLinksInner_cont">
                <span className="sideButtonName">{t('reseller')}</span>
                <div className="sidebarLinkArrow_cont">
                  {!isSupplierSubLinksVisible && (<KeyboardArrowDownIcon />)}
                  {isSupplierSubLinksVisible && (<KeyboardArrowUpIcon />)}
                  {/* <KeyboardArrowDownIcon /> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* S U P P L I E R    S U B L I N K S */}

        <div
          className={`salesSubLink_cont ${isSupplierSubLinksVisible ? 'visible' : ''}`}
        >
          <NavLink
            to="/home/create-broadcast"
            activeclassname="active"
            className="sideSubNavLink"
            style={{ marginTop: "8px" }}
          >
            <div className="sideSubButton_cont">
              <div className="buttonIcon_cont activeb">
                <SendIcon
                  style={{ fontSize: "20px", color: "#00CCDD" }}
                ></SendIcon>{" "}
              </div>
              <span className="sideSubButtonName">{t('broadcast')}</span>
            </div>
          </NavLink>
          <NavLink
            to="/home/broadcasts"
            activeclassname="active"
            className="sideSubNavLink"
          >
            <div className="sideSubButton_cont">
              <div className="buttonIcon_cont activeb">
                <ViewWeekIcon
                  style={{ fontSize: "20px", color: "#E85C0D" }}
                ></ViewWeekIcon>{" "}
              </div>
              <span className="sideSubButtonName">{t('view')}</span>
            </div>
          </NavLink>
          <NavLink
            to="/home/search-product"
            activeclassname="active"
            className="sideSubNavLink"
          >
            <div className="sideSubButton_cont">
              <div className="buttonIcon_cont activeb">
                <SearchIcon
                  style={{ fontSize: "20px", color: "#9EB23B" }}
                ></SearchIcon>{" "}
              </div>
              <span className="sideSubButtonName">{t('search')}</span>
            </div>
          </NavLink>
        </div>

        {/* C O N T A C T S */}

        <div
          className="sideButtonLink_cont"
          id="sideButtonLink"
        >
          <NavLink
            to="/home/contacts"
            activeclassname="active"
            className="sideNavLink"
          >
            <div className="sideButton_cont">
              <div className="buttonIcon_cont activeb">
                <PeopleIcon
                  style={{ fontSize: "20px", color: "blue" }}
                ></PeopleIcon>{" "}
              </div>
              <span className="sideButtonName">{t('contacts')}</span>
            </div>
          </NavLink>
        </div>

        {/* S H I P P I N G */}

        {/* <div
          className="sideButtonLink_cont"
          id="sideButtonLink"
        >
          <NavLink
            to="/home/shipping"
            activeclassname="active"
            className="sideNavLink"
          >
            <div className="sideButton_cont">
              <div className="buttonIcon_cont activeb">
                <LocalShippingIcon
                  style={{ fontSize: "20px", color: "purple" }}
                ></LocalShippingIcon>{" "}
              </div>
              <span className="sideButtonName">{t('shipping')}</span>
            </div>
          </NavLink>
        </div> */}

        {/* W H A T S A P P */}

        <div
          className="sideButtonLink_cont"
          id="sideButtonLink"
        >
          <NavLink
            to="/home/whatsapp"
            activeclassname="active"
            className="sideNavLink"
          >
            <div className="sideButton_cont">
              <div className="buttonIcon_cont activeb">
                <WhatsAppIcon
                  style={{ fontSize: "20px", color: "green" }}
                ></WhatsAppIcon>{" "}
              </div>
              <span className="sideButtonName">{t('whatsapp')}</span>
            </div>
          </NavLink>
        </div>

        {/* I N T E G R A T I O N */}

        <div
          className="sideButtonLink_cont sideIntegrationBtn"
          id="sideButtonLink"
          onClick={toggleIntegrationSubLinks}
          activeclassname="active"
        >
          <div
            activeclassname="active"
            className="sideNavLink"
          >
            <div className="sideButton_cont">
              <div className="buttonIcon_cont activeb">
                <IntegrationInstructionsIcon
                  style={{ fontSize: "20px", color: "orange" }}
                ></IntegrationInstructionsIcon>{" "}
              </div>
              <div className="sidebarLinksInner_cont">
                <span className="sideButtonName">{t('integration')}</span>
                <div className="sidebarLinkArrow_cont">
                  {!isIntegrationSubLinksVisible && (<KeyboardArrowDownIcon />)}
                  {isIntegrationSubLinksVisible && (<KeyboardArrowUpIcon />)}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* I N T E G R A T I O N   S U B L I N K S */}

        <div
          className={`salesSubLink_cont ${isIntegrationSubLinksVisible ? 'visible' : ''}`}
        >
          <NavLink
            to="/home/view-marketplace"
            activeclassname="active"
            className="sideSubNavLink"
            style={{ marginTop: "8px" }}
          >
            <div className="sideSubButton_cont">
              <div className="buttonIcon_cont activeb">
                <StoreIcon
                  style={{ fontSize: "20px", color: "#E85C0D" }}
                ></StoreIcon>{" "}
              </div>
              <span className="sideSubButtonName">{t('marketplace')}</span>
            </div>
          </NavLink>
          <NavLink
            to="/home/new-marketplace"
            activeclassname="active"
            className="sideSubNavLink"
          >
            <div className="sideSubButton_cont">
              <div className="buttonIcon_cont activeb">
                <AddBusinessIcon
                  style={{ fontSize: "20px", color: "#6256CA" }}
                ></AddBusinessIcon>{" "}
              </div>
              <span className="sideSubButtonName">{t('new')}</span>
            </div>
          </NavLink>
        </div>

      </div>
    </div>
  );
};

export default SideBar;
