import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const ContactTableLoader = () => {
  return (
    <Stack spacing={1}>
      {/* For variant="text", adjust the height via font-size */}
      <Skeleton variant="rounded" width="100%" height={50} />
      <Skeleton variant="rounded" width="100%" height={50} />
      <Skeleton variant="rounded" width="100%" height={50} />
      <Skeleton variant="rounded" width="100%" height={50} />
      <Skeleton variant="rounded" width="100%" height={50} />
      <Skeleton variant="rounded" width="100%" height={50} />
    </Stack>
  )
}

export default ContactTableLoader;