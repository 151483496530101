import React, { useState } from "react";
import ContactsContext from "./ContactsContext";

const ContactsContextProvider = ({ children }) => {

  const [contactsArray, setContactsArray] = useState(null);

  return (
    <ContactsContext.Provider value={{ contactsArray, setContactsArray }}>
      {children}
    </ContactsContext.Provider>
  )

}

export default ContactsContextProvider;