import React, { useState, useContext } from 'react'
import './Reseller.css';
import TemplateContext from '../../Context/TemplateContext';
import SegmentContext from '../../Context/SegmentContext';
const Reseller = () => {

  const { templateArray } = useContext(TemplateContext);
  const { segmentArray } = useContext(SegmentContext);

  return (
    <div className='supplier_main'>
      <h1>Supplier</h1>
    </div>
  )
}

export default Reseller;