import React from 'react';
import './PageNotFound.css';
import PageNotFoundSvg from '../../assets/pageNotFoundSvg.svg'
import { Link } from 'react-router-dom';

const PageNotFound = () => {
  return (
    <div className='notFoundMain_cont'>
      <div className="notfoundNavbar">
        <Link to='/home/dashboard' className='btnFill'>Home</Link>
      </div>
      <div className="notFoundSub_cont">
        <img src={PageNotFoundSvg} className='notFoundImg' alt="Page Not Found" />
      </div>
      <div className="notFoundHead_cont">
        <h2>Page Not Found</h2>
      </div>
    </div>
  )
}
export default PageNotFound;