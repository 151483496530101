const tableStyles = {
  headRow: {
    style: {
      position: "sticky",
      top: 0,
      color: "rgb(14, 113, 195)",
      fontSize: "0.9rem",
      lineHeight: "24px",
    },
  },
  headCells: {
    style: {
      // border: "1px solid red",
      padding: "10px",
      textAlign: "left",

    },
  },
  cells: {
    style: {
      // border: "1px solid blue",
      padding: "10px",
      fontSize: "0.8rem",
      color: "black",
      lineHeight: "25px",
    },
  }
}
export default tableStyles;