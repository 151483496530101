import { dashboardData } from "../DashboardData"
export const adSpendStyle = {
    data: {
      labels: dashboardData.map((data) => data.time),
      datasets: [{
        label: "Units sold",
        data: dashboardData.map((data) => data.AdSpend),
        borderColor: 'rgb(14, 113, 195)',
        // backgroundColor: [
        //   'rgba(255, 26, 104, 0.2)',
        //   'rgba(54, 162, 235, 0.2)',
        //   'rgba(255, 206, 86, 0.2)',
        //   'rgba(75, 192, 192, 0.2)',
        //   'rgba(153, 102, 255, 0.2)',
        //   'rgba(255, 159, 64, 0.2)',
        //   'rgba(0, 0, 0, 0.2)',
        //   '#BFF6C3'
        // ],

      }]
    },
    options: {
      scales: {
        y: {
          grid: {
            display: false,
            beginAtZero: true
          },
          // ticks: {
          //   color: '#fff'
          // }
        },
        x: {
          grid: {
            display: false,
          },
          // ticks: {
          //   color: '#fff'
          // },
        }
      },
      plugins: {
        legend: {
          display: false,
        }
      },
      elements: {
        line: {
          tension: 0.3  // Change the tension here
        }
      }
    }
  }