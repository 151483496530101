import React, { useEffect, useState } from 'react';
import './Signup.css';
import SignUpSvg from '../../../assets/signupSvg.svg';

import { TextField } from '@mui/material';
import Alert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';

const textFieldStyle = {
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      // borderColor: 'rgb(23, 193, 232)',
      borderRadius: "15px",
      // borderWidth: "1.5px",
    },
    '&:hover fieldset': {
      // borderColor: 'black',
      borderRadius: "15px",
    },
    '&.Mui-focused fieldset': {
      // borderColor: 'black',
      borderRadius: "15px",
    },
  },
}

const Signup = () => {
  const navigate = useNavigate();

  const [emptyUserPassAlert, setEmptyUserPassAlert] = useState(false);
  const [confirmPassAlert, setConfirmPassAlert] = useState(false);
  const [userExistAlert, setUserExistAlert] = useState(false);
  const [signupLoader, setSignupLoader] = useState(false);

  const [confirmPassword, setConfirmPassword] = useState('');

  const [signupDetails, setSignUpDetails] = useState({
    emailId: "",
    password: "",
    contactNumber: "",
    contactPerson: "",
    companyName: ""
  })


  const handleChange = (e) => {
    setSignUpDetails({ ...signupDetails, [e.target.name]: e.target.value });
  }

  const handleConfirmPassChange = (e) => {
    setConfirmPassword(e.target.value);
  }


  // F U N C T I O N   F O R   S I G N U P

  const handleSignup = async (e) => {
    e.preventDefault();
    const signup_URL = 'https://zumi.com.au/zumi/api/users/register';

    // Show loader
    setSignupLoader(true);

    if (signupDetails.emailId === "" ||
      signupDetails.password === "" ||
      signupDetails.contactNumber === "" ||
      signupDetails.contactPerson === "" ||
      signupDetails.companyName === ""
    ) {
      setEmptyUserPassAlert(true);
      setConfirmPassAlert(false);
      setUserExistAlert(false);
      // Hide loader
      setSignupLoader(false);
      return;
    }

    if (signupDetails.password !== confirmPassword) {
      setConfirmPassAlert(true);
      setEmptyUserPassAlert(false);
      setUserExistAlert(false);
      // Hide loader
      setSignupLoader(false);
      return;
    }

    const signupDetailsJson = JSON.stringify(signupDetails);

    console.log(signupDetailsJson);
    try {
      // Send POST request
      const response = await fetch(signup_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: signupDetailsJson,
      });

      // Check if response is not OK
      if (!response.ok) {
        // Handle specific status codes
        if (response.status === 409) {
          const errorData = await response.json();
          if (errorData.result === 'fail' && errorData.message === 'User already exists') {
            setUserExistAlert(true);
          } else {
            // Handle other types of errors if needed
            console.error('Error:', errorData.message);
          }
        } else {
          // For other HTTP errors
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      } else {
        // Successful response
        const data = await response.json();
        console.log(data);
        navigate('/signin');
        setUserExistAlert(false);
      }
    } catch (err) {
      console.error('Error posting data:', err);
    } finally {
      // Hide loader
      setSignupLoader(false);
    }
  };



  useEffect(() => {
    if (signupDetails.emailId &&
      signupDetails.companyName &&
      signupDetails.contactNumber &&
      signupDetails.contactPerson &&
      signupDetails.password &&
      confirmPassword) {
      setEmptyUserPassAlert(false);
    }
  }, [signupDetails, confirmPassword]);


  return (
    <div className='signupMain_cont'>
      <div className="signupHeading_cont">
        <h2 className='signupHeading'>Sign Up</h2>
      </div>

      <div className="signupSub_cont">
        <div className="signupSub_cont1">
          <img src={SignUpSvg} className='loginSvg' alt="" />
        </div>
        <div className="signupSub_cont2">
          <div className="signupForm_cont">
            <div>

              <div className="signupRow1">
                <div className="signupFormInput_cont" style={{ marginRight: "10px" }}>
                  <label htmlFor="contactPerson" className="welcomeFormLabel">Your Name  <span className="asterisk">*</span></label>
                  <TextField
                    className='welcomeFormInput'
                    InputLabelProps={{ style: { display: 'none' } }}
                    inputProps={{ autoComplete: 'off' }} // Disable autocomplete
                    type='text'
                    name="contactPerson"
                    id="outlined-basic"
                    placeholder="Enter your name"
                    variant="outlined"
                    sx={textFieldStyle}
                    value={signupDetails.contactPerson}
                    onChange={handleChange}
                  />
                </div>

                <div className="signupFormInput_cont">
                  <label htmlFor="companyName" className="welcomeFormLabel">Company Name  <span className="asterisk">*</span></label>
                  <TextField
                    className='welcomeFormInput'
                    InputLabelProps={{ style: { display: 'none' } }}
                    inputProps={{ autoComplete: 'off' }} // Disable autocomplete
                    type='text'
                    name="companyName"
                    id="outlined-basic"
                    placeholder="Enter your company name"
                    variant="outlined"
                    sx={textFieldStyle}
                    value={signupDetails.companyName}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="signupRow2">
                <div className="signupFormInput_cont" style={{ marginRight: "10px" }}>
                  <label htmlFor="emailId" className="welcomeFormLabel">Email  <span className="asterisk">*</span></label>
                  <TextField
                    className='welcomeFormInput'
                    InputLabelProps={{ style: { display: 'none' } }}
                    inputProps={{ autoComplete: 'off' }} // Disable autocomplete
                    type='email'
                    name="emailId"
                    id="outlined-basic"
                    placeholder="Enter your email"
                    variant="outlined"
                    sx={textFieldStyle}
                    value={signupDetails.emailId}
                    onChange={handleChange}
                  />
                </div>

                <div className="signupFormInput_cont">
                  <label htmlFor="contactNumber" className="welcomeFormLabel">Mobile  <span className="asterisk">*</span></label>
                  <TextField
                    className='welcomeFormInput'
                    InputLabelProps={{ style: { display: 'none' } }}
                    inputProps={{ autoComplete: 'off' }} // Disable autocomplete
                    type='number'
                    name="contactNumber"
                    id="outlined-basic"
                    placeholder="Enter mobile number"
                    variant="outlined"
                    sx={textFieldStyle}
                    value={signupDetails.contactNumber}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="signupRow3">
                <div className="signupFormInput_cont" style={{ marginRight: "10px" }}>
                  <label htmlFor="password" className="welcomeFormLabel">Password  <span className="asterisk">*</span></label>
                  <TextField
                    className='welcomeFormInput'
                    InputLabelProps={{ style: { display: 'none' } }}
                    inputProps={{ autoComplete: 'off' }} // Disable autocomplete
                    type='password'
                    name="password"
                    id="outlined-basic"
                    placeholder="Enter Password"
                    variant="outlined"
                    sx={textFieldStyle}
                    value={signupDetails.password}
                    onChange={handleChange}
                  />
                </div>

                <div className="signupFormInput_cont">
                  <label htmlFor="confirmPassword" className="welcomeFormLabel">Confirm Password  <span className="asterisk">*</span></label>
                  <TextField
                    className='welcomeFormInput'
                    InputLabelProps={{ style: { display: 'none' } }}
                    inputProps={{ autoComplete: 'off' }} // Disable autocomplete
                    type='password'
                    name="confirmPassword"
                    id="outlined-basic"
                    placeholder="Confirm Password"
                    variant="outlined"
                    disabled={!signupDetails.password}
                    sx={textFieldStyle}
                    value={confirmPassword}
                    onChange={handleConfirmPassChange}
                  />
                </div>
              </div>
              <div className="formInput_cont signupBtn_cont">
                <button className='btnFill' onClick={handleSignup}>Signup</button>
                {signupLoader && <div className="loader"></div>}
              </div>
            </div>
            <div className="loginAlert_cont">
              {emptyUserPassAlert &&
                <Alert severity="warning" className='loginAlert' id='incorrectAlert'>Please fill mandatory fields.</Alert>
              }
              {confirmPassAlert &&
                <Alert severity="error" className='loginAlert' id='incorrectAlert'>Passwords didn't match.</Alert>
              }
              {/* {loginSuccessAlert &&
                <Alert severity="success" className='loginAlert' id='incorrectAlert'>Incorrect username or password.</Alert>
              } */}
              {userExistAlert &&
                <Alert severity="error" className='loginAlert' id='incorrectAlert'>User already exists.</Alert>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Signup;