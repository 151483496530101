import React, { useState } from "react";
import RevenueContext from "./RevenueContext";
const RevenueContextProvider = ({ children }) => {

  const [revenueChartArray, setRevenueChartArray] = useState([]);

  return (
    <RevenueContext.Provider value={{ revenueChartArray, setRevenueChartArray }}>
      {children}
    </RevenueContext.Provider>
  )

}

export default RevenueContextProvider;