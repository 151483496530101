import React, { useState } from "react";

import UnitsSoldContext from "./UnitsSoldContext";

const UnitsSoldContextProvider = ({ children }) => {

  const [unitsSoldArray, setUnitsSoldArray] = useState([]);

  return (
    <UnitsSoldContext.Provider value={{ unitsSoldArray, setUnitsSoldArray }}>
      {children}
    </UnitsSoldContext.Provider>
  )
}

export default UnitsSoldContextProvider;