import React, { useState, useEffect, useContext } from 'react';
import './Dashboard.css';
import LocationSelect from './LocationSelect/LocationSelect';
import SalesChart from './Sales/SalesChart';
import UnitsChart from './UnitsSold/UnitsChart';
import ProfitChart from './Profit/ProfitChart';
import UnfulfilledChart from './Unfulfilled/UnfulfilledChart';
import AdSpendChart from './AdSpend/AdSpend';
import BuyerChart from './Buyer/BuyerChart';
import InventoryChart from './Inventory/InventoryChart';

import Breadcrumbs from '../Toolbar/Breadcrumbs';

// import { salesStyle } from './Sales/SalesStyle';
// import SalesStyle from './Sales/SalesStyle';
// import { unitsStyle } from './UnitsSold/UnitsStyle';
// import { profitStyle } from './Profit/ProfitStyle';
import { unfulfilledStyle } from './Unfulfilled/UnfulfilledStyle';
import { adSpendStyle } from './AdSpend/AdSpendStyle';
import { buyerStyle } from './Buyer/BuyerStyle';
import { inventoryStyle } from './Inventory/InventoryStyle';

import { useTranslation } from 'react-i18next';

import TotalSalesContext from '../../Context/TotalSalesContext';
import UnitsSoldContext from '../../Context/UnitsSoldContext';
import ProfitContext from '../../Context/ProfitContext';
// import MarketplaceContext from '../../Context/MarketplaceContext';
import SelectedMarketplaceContext from '../../Context/SelectedMarketplaceContext';

import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import SellIcon from '@mui/icons-material/Sell';
import LoyaltyIcon from '@mui/icons-material/Loyalty';

import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';

const Dashboard = () => {
  const { t } = useTranslation();

  const { selectedMarketplace, setSelectedMarketplace } = useContext(SelectedMarketplaceContext);
  const { totalSalesArray, setTotalSalesArray } = useContext(TotalSalesContext);
  const { unitsSoldArray, setUnitsSoldArray } = useContext(UnitsSoldContext);
  const { profitArray, setProfitArray } = useContext(ProfitContext);

  const [roughStartDate, setRoughStartDate] = useState(null);
  const [roughEndDate, setRoughEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [combinedDate, setCombinedDate] = useState(null);

  const [activeListing, setActiveListing] = useState([]);
  const [buyBoxListing, setBuyBoxListing] = useState([]);
  const [overpriceListing, setOverpriceListing] = useState([]);
  const [noSaleListing, setNoSaleListing] = useState([]);
  const [salesData, setSalesData] = useState([]);
  const [unitsSoldData, setUnitsSoldData] = useState([]);
  const [profitData, setProfitData] = useState([]);
  const [accountHealth, setAccountHealth] = useState();

  const [totalActiveListing, setTotalActiveListing] = useState();
  const [totalBuyBoxListing, setTotalBuyBoxListing] = useState();
  const [totalOverpriceListing, setTotalOverpriceListing] = useState();
  const [totalNoSaleListing, setTotalNoSaleListing] = useState();
  const [totalSales, setTotalSales] = useState();
  const [totalUnitsSold, setTotalUnitsSold] = useState();
  const [totalProfit, setTotalProfit] = useState();

  const [lastActiveListing, setLastActiveListing] = useState();
  const [lastBuyBoxListing, setLastBuyBoxListing] = useState();
  const [lastOverpriceListing, setLastOverpriceListing] = useState();
  const [lastNoSaleListing, setLastNoSaleListing] = useState();

  const authToken = sessionStorage.getItem('authToken');

  const handleStartChange = (date) => {
    setRoughStartDate(date);
  }
  const handleEndChange = (date) => {
    setRoughEndDate(date);
  }

  // F U N T I O N   T O  S E T   R O U G H   D A T E   I N T O   F O R M A T T E D   D A T E
  useEffect(() => {
    if (roughStartDate) {
      const formattedStartDate = roughStartDate.format('MMM D, YYYY');
      // console.log('Formatted Start Date:', formattedStartDate);
      setStartDate(formattedStartDate);
    }
    if (roughEndDate) {
      const formattedEndDate = roughEndDate.format('MMM D, YYYY');
      // console.log('Formatted End Date:', formattedEndDate);
      setEndDate(formattedEndDate);
    }
  }, [roughEndDate, roughStartDate]);

  //  F U N C T I O N   T O   C O M B I N E  D A T E S 
  useEffect(() => {
    if (startDate && endDate) {
      setCombinedDate(startDate + " - " + endDate)
    }
  }, [startDate, endDate]);


  const activeListing_URL = `${t("baseUrl")}/zumi/api/dashboard/totallisting?dateRange=${combinedDate}`;
  const butBoxListing_URL = `${t("baseUrl")}/zumi/api/dashboard/buyboxlisting?dateRange=${combinedDate}`;
  const overpriceListing_URL = `${t("baseUrl")}/zumi/api/dashboard/overpricelisting?dateRange=${combinedDate}`;
  const noSaleListing_URL = `${t("baseUrl")}/zumi/api/dashboard/nosalelisting?dateRange=${combinedDate}`;
  const salesData_URL = `${t("baseUrl")}/zumi/api/dashboard/totalsales?dateRange=${combinedDate}`;
  const unitsSold_URL = `${t("baseUrl")}/zumi/api/dashboard/unitsold?dateRange=${combinedDate}`;
  const profit_URL = `${t("baseUrl")}/zumi/api/dashboard/profit?dateRange=${combinedDate}`;
  const accountHealth_URL = `${t("baseUrl")}/zumi/api/dashboard/accounthealthrating`;



  //  F E T C H    A C T I V E   L I S T I N G S
  useEffect(() => {

    fetch(activeListing_URL, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${authToken}`, // Add the Authorization header
        'Content-Type': 'application/json' // Set content type if necessary
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        // console.log('Active Listings: ', data);
        setActiveListing(data);

        if (data.length > 0) {
          const lastObject = data[data.length - 1];
          setLastActiveListing(lastObject.count || 0); // Set country state, default to empty string if null
        }
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });
  }, [combinedDate, selectedMarketplace]);


  //  F E T C H    B U Y B O X   L I S T I N G S
  useEffect(() => {

    fetch(butBoxListing_URL, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${authToken}`, // Add the Authorization header
        'Content-Type': 'application/json' // Set content type if necessary
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        // console.log('Buy Box Listings:', data);
        setBuyBoxListing(data);
        if (data.length > 0) {
          const lastObject = data[data.length - 1];
          setLastBuyBoxListing(lastObject.count || 0); // Set country state, default to empty string if null
        }
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });
  }, [combinedDate, selectedMarketplace]);


  //  F E T C H    O V E R P R I C E   L I S T I N G S
  useEffect(() => {

    fetch(overpriceListing_URL, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${authToken}`, // Add the Authorization header
        'Content-Type': 'application/json' // Set content type if necessary
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        // console.log('Overprice Listings:', data);
        setOverpriceListing(data);
        if (data.length > 0) {
          const lastObject = data[data.length - 1];
          setLastOverpriceListing(lastObject.count || 0); // Set country state, default to empty string if null
        }
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });
  }, [combinedDate, selectedMarketplace]);


  //  F E T C H   N O S A L E   L I S T I N G S
  useEffect(() => {

    fetch(noSaleListing_URL, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${authToken}`, // Add the Authorization header
        'Content-Type': 'application/json' // Set content type if necessary
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        // console.log('No sale Listings:', data);
        setNoSaleListing(data);
        if (data.length > 0) {
          const lastObject = data[data.length - 1];
          setLastNoSaleListing(lastObject.count || 0); // Set country state, default to empty string if null
        }
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });
  }, [combinedDate, selectedMarketplace]);


  //  F E T C H   T O T A L   S A L E S
  useEffect(() => {

    fetch(salesData_URL, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${authToken}`, // Add the Authorization header
        'Content-Type': 'application/json' // Set content type if necessary
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setSalesData(data);
        setTotalSalesArray(data);
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });
  }, [combinedDate, selectedMarketplace]);

  useEffect(() => {
    if (salesData) {
      // Calculate the sum of all counts
      const sum = salesData.reduce((acc, item) => acc + (item.count || 0), 0);
      setTotalSales(sum);
      // console.log("Total Sales:", totalSales)
    }
  }, [salesData]);


  //  F E T C H   U N I T S   S O L D 
  useEffect(() => {

    fetch(unitsSold_URL, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${authToken}`, // Add the Authorization header
        'Content-Type': 'application/json' // Set content type if necessary
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        // console.log('Units Data:', data);
        setUnitsSoldData(data);
        setUnitsSoldArray(data);
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });
  }, [combinedDate, selectedMarketplace]);

  useEffect(() => {
    if (unitsSoldData) {
      // Calculate the sum of all counts
      const sum = unitsSoldData.reduce((acc, item) => acc + (item.count || 0), 0);
      setTotalUnitsSold(sum);
      // console.log("Units Sold:", totalUnitsSold);
    }
  }, [unitsSoldData]);


  //  F E T C H   P R O F I T
  useEffect(() => {

    fetch(profit_URL, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${authToken}`, // Add the Authorization header
        'Content-Type': 'application/json' // Set content type if necessary
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        // console.log('Profit Data:', data);
        setProfitData(data);
        setProfitArray(data);
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });
  }, [combinedDate, selectedMarketplace]);

  useEffect(() => {
    if (profitData) {
      // Calculate the sum of all counts
      const sum = profitData.reduce((acc, item) => acc + (item.count || 0), 0);
      setTotalProfit(sum);
      // console.log("Total Profit:", totalProfit);
    }
  }, [profitData]);

  //  F E T C H   A C C O U N T   H E A L T H
  useEffect(() => {

    fetch(accountHealth_URL, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${authToken}`, // Add the Authorization header
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        // console.log("Account Health : ", response);
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });
  }, [combinedDate, selectedMarketplace]);


  return (
    <div className='dashboard_main'>
      <Breadcrumbs />
      <div className="locationMain_cont">
        <LocationSelect />
        <div className="dateRangePickerMain_cont" style={{ display: 'flex', gap: '16px' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs} >
            <DatePicker
              label="Start Date"
              value={roughStartDate}
              onChange={handleStartChange}
              maxDate={dayjs()}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="End Date"
              value={roughEndDate}
              onChange={handleEndChange}
              maxDate={dayjs()}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>
      </div>

      {/* F I R S T   R O W  */}

      <div className="dfirst_row">
        <div className="dfirstrowElm">
          <div className="data_cont">
            <div className="dataHeading_cont">
              <span>{t('activeListings')}</span>
            </div>
            <h5 className="firstrowdata">
              {lastActiveListing}
            </h5>
          </div>
          <div className="icon_cont"><ToggleOnIcon style={{ width: "40px", height: "40px", color: "green" }} /></div>
        </div>
        <div className="dfirstrowElm">
          <div className="data_cont">
            <div className="dataHeading_cont">
              <span>{t('buyBoxListing')}</span>
            </div>
            <h5 className="firstrowdata">
              {lastBuyBoxListing}
            </h5>
          </div>
          <div className="icon_cont"><ShoppingBagIcon style={{ width: "30px", height: "30px", color: "brown" }} /></div>
        </div>
        <div className="dfirstrowElm">
          <div className="data_cont">
            <div className="dataHeading_cont">
              <span>{t('overpriceListing')}</span>
            </div>
            <h5 className="firstrowdata">
              {lastOverpriceListing}
            </h5>
          </div>
          <div className="icon_cont"><SellIcon style={{ width: "30px", height: "30px", color: "red" }} /></div>
        </div>
        <div className="dfirstrowElm dfirstrowfourthElm">
          <div className="data_cont">
            <div className="dataHeading_cont">
              <span>{t('noSaleListings')}</span>
            </div>
            <h5 className="firstrowdata">
              {lastNoSaleListing}
            </h5>
          </div>
          <div className="icon_cont"><LoyaltyIcon style={{ width: "30px", height: "30px", color: "orange" }} /></div>
        </div>
      </div>

      {/* S E C O N D   R O W */}

      <div className="dsecond_row">
        <div className=" dsecondrowElm dsecondFirstElm">
          <div className="dcont_heading">
            <span>{t('totalSales')}</span>
            <h5 className="firstrowdata">
              {totalSales}
            </h5>
          </div>
          <div className="graph_cont">
            <SalesChart />
          </div>
        </div>
        <div className="dsecondrowElm dsecondSecondElm">
          <div className="dcont_heading">
            <span>{t('unitsSold')}</span>
            <h5 className="firstrowdata">
              {totalUnitsSold}
            </h5>
          </div>
          <div className="graph_cont">
            <UnitsChart />
          </div>
        </div>
      </div>

      {/* T H I R D   R O W */}

      <div className="dthird_row">
        <div className="dthirdrowElm dthirdrowfirstElm">
          <div className="dcont_heading">
            <span>{t('profit')}</span>
            <h5 className="firstrowdata">
              {totalProfit}
            </h5>
          </div>
          <div className="graph_cont">
            <ProfitChart />
          </div>
        </div>
        <div className="dthirdrowElm dthirdrowsecondElm">
          <div className="dcont_heading">
            <span>{t('unfulfilledOrders')}</span>
            <h5 className="firstrowdata">
              Coming Soon
            </h5>
          </div>
          <div className="graph_cont">
            {/* <UnfulfilledChart unfulfilledData={unfulfilledStyle.data} options={unfulfilledStyle.options} /> */}
          </div>
        </div>
      </div>

      {/* F O U R T H   R O W  */}

      <div className="dfourth_row">
        <div className="dfourthrowElm dfourthrowfirstElm">
          <div className="dcont_heading">
            <span>{t('adSpend')}</span>
            <h5 className="firstrowdata">
              Coming Soon
            </h5>
          </div>
          <div className="graph_cont">
            {/* <AdSpendChart adSpendData={adSpendStyle.data} options={adSpendStyle.options} /> */}
          </div>
        </div>
        <div className="dfourthrowElm dfourthrowsecondElm">
          <div className="dcont_heading">
            <span>{t('buyerMessages')}</span>
            <h5 className="firstrowdata">
              Coming Soon
            </h5>
          </div>
          <div className="graph_cont">
            {/* <BuyerChart buyerData={buyerStyle.data} options={buyerStyle.options} /> */}
          </div>
        </div>
      </div>

      {/* F I F T H    R O W */}

      <div className="dfifth_row">
        <div className="dfifthrowElm dfifthrowfirstElm">
          <div className="dcont_heading">
            <span>{t('inventoryPerformance')}</span>
            <h5 className="firstrowdata">
              Coming Soon
            </h5>
          </div>
          <div className="graph_cont">
            {/* <InventoryChart inventoryData={inventoryStyle.data} options={inventoryStyle.options} /> */}
          </div>
        </div>

        <div className="dfifthrowElm dfifthrowsecondElm">
          <div className="dcont_heading">
            <span>{t('accounthealth')}</span>
          </div>
          <h5 className='firstrowdata'>
            {t('normal')}
          </h5>
        </div>
      </div>

    </div>
  )
}

export default Dashboard;