import React from 'react'
import { useTranslation } from 'react-i18next';


export const NoDataComponent = () => {
  const { t } = useTranslation();


  return (
    <div style={{ padding: "20px" }}>{t('noTemplateDataPara')}</div>
  )
}


export default NoDataComponent;
