import React, { useState, useEffect, useRef } from 'react'
import './Toolbar.css'
import SearchIcon from '@mui/icons-material/Search';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import PersonIcon from '@mui/icons-material/Person';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import LogoutIcon from '@mui/icons-material/Logout';

import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

import Select from '@mui/material/Select';
import { useNavigate } from 'react-router-dom';
import ZumiBrandImg from '../../../src/assets/zumiBrandImg.png';
import UserProfile from '../../../src/assets/userProfile1Crop.jpg'

import { routeConfig } from './routeconfig';
import { useLocation } from 'react-router-dom';


const Toolbar = () => {

  const location = useLocation();
  const currentPath = location.pathname;
  const pageName = routeConfig[currentPath]?.name || 'Not Found';

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const { t } = useTranslation();
  const navigate = useNavigate();

  // const { tokenFromContext, setTokenFromContext } = useContext(TokenContext);
  const authToken = sessionStorage.getItem('authToken');

  const [langName, setLangName] = useState('');

  const handleLangChange = (e) => {
    setLangName(e.target.value);
    i18n.changeLanguage(e.target.value);
  };

  const handleLogout = () => {
    if (authToken) {
      // setTokenFromContext('');
      sessionStorage.removeItem('authToken');
      sessionStorage.removeItem('userId');

      navigate('/');
    }
  }

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };
  useEffect(() => {
    // Add event listener to document
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Clean up event listener
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="toolbarMain_cont">
      <div className="toolbarBrandMain_cont">
        <img src={ZumiBrandImg} alt="brandImg" style={{ height: 40, width: 80 }} />
      </div>
      <div className="toolsMain_cont">
        <div className="localesMain_cont">
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              name='locales'
              value={langName}
              onChange={handleLangChange}
              displayEmpty
              renderValue={(selected) => {
                if (selected === '') {
                  return "English";
                }
                if (selected === 'en') {
                  return "English";
                }
                if (selected === "fr") {
                  return "French";
                }
                if (selected === "hi") {
                  return "Hindi";
                }
                return selected;
              }}
            >
              <MenuItem value="en">English</MenuItem>
              <MenuItem value="fr">French</MenuItem>
              <MenuItem value="hi">Hindi</MenuItem>
            </Select>
          </FormControl>
        </div>


        <div className="tnotification_cont centerElm">
          <NotificationsIcon className='tnotification_icon' style={{ color: "gold", height: 35, width: 35 }} />
        </div>


        <div className="tsignIn_cont centerElm" onClick={toggleDropdown}>
          <img src={UserProfile} alt="profileIcon" className='userProfileImg' />

          {isDropdownOpen && (
            <div className="dropdownMenu" ref={dropdownRef}>
              <a href="" className='tDropdownMenuLink'>
                <div className="profileIcon_cont"><PersonIcon /></div>
                <div className="tDropdownMenuName">Profile</div>
              </a>
              <a href="" className='tDropdownMenuLink'>
                <div className="profileIcon_cont"><AccountBoxIcon /></div>
                <div className="tDropdownMenuName">My Account</div>
              </a>
              <a href="" className='tDropdownMenuLink settingsLinkCont'>
                <div className="profileIcon_cont"><SettingsIcon /></div>
                <div className="tDropdownMenuName">Settings</div>
              </a>
              <hr className='tDropdownHR' />
              <div href="" className='tDropdownMenuLink logoutLinkCont' onClick={handleLogout}>
                <div className="profileIcon_cont"><LogoutIcon /></div>
                <div className="tDropdownMenuName">Logout</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Toolbar;