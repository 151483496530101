import React from 'react';
import './Products.css';

import DataTable from 'react-data-table-component';
import LocationSelect from '../Dashboard/LocationSelect/LocationSelect';
import Breadcrumbs from '../Toolbar/Breadcrumbs';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import { useTranslation } from 'react-i18next';

const Products = () => {

  return (
    <div className='products_main'>
      <h1>Products</h1>
    </div >
  )
}

export default Products;