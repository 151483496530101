import React, { useState, useEffect, useRef, useContext } from 'react';
import './CreateSegment.css'

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import SegmentContext from '../../../../Context/SegmentContext';

import { useTranslation } from 'react-i18next';

import TokenContext from '../../../../Context/TokenContext';

const CreateSegment = ({ closeModal }) => {

  const { t } = useTranslation();
  // const { tokenFromContext } = useContext(TokenContext);
  const { segmentArray } = useContext(SegmentContext);    // context

  const createSegment_URL = `${t("baseUrl")}/zumi/api/createsegment`

  const endOfListRef = useRef(null);

  const [spaceError, setSpaceError] = useState('');
  const [hasCapitalLetter, setHasCapitalLetter] = useState(false);
  const [nameError, setNameError] = useState(false);


  const authToken = sessionStorage.getItem('authToken');


  const filterItems = [
    { id: 1, value: "name", name: 'Name' },
    { id: 2, value: "phone", name: 'Phone' },
    { id: 2, value: "country", name: 'Country' },
    { id: 3, value: "city", name: 'City' },
    { id: 4, value: "zipcode", name: 'Zip code' },
  ];


  const operationItems = {
    name: [
      { id: 1, value: "=", name: "Equals" },
      { id: 2, value: "like", name: "Like" },
    ],
    phone: [
      { id: 4, value: "=", name: "Equals" },
    ],
    city: [
      { id: 4, value: "=", name: "Equals" },
    ],
    zipcode: [
      { id: 5, value: "=", name: "Equals" },
    ],
    country: [
      { id: 6, value: "=", name: "Equals" },
    ]
  }


  // AUTOFOCUS 
  const inputRef = useRef(null);
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);


  // T O G G L E   B U T T O N

  const leftClick = () => {
    const ToggleBtn = document.querySelector('#ToggleBtn');
    ToggleBtn.style.left = '0'
  }

  const rightClick = () => {
    const ToggleBtn = document.querySelector('#ToggleBtn');
    ToggleBtn.style.left = '50%'
  }

  // Initialize state with empty criteria
  const [newSegmentData, setNewSegmentData] = useState({
    segmentName: '',
    description: '',
    criteria: [
      { column: '', value: '', operation: '' } // Initialize with empty values
    ],
  });

  // H A N D L E   S E G M E N T   N A M E
  const handleSegmentNameChange = (e) => {
    // checking template name has space or not
    const hasSpaces = /\s/.test(newSegmentData.segmentName);
    if (hasSpaces) {
      setSpaceError('Spaces are not allowed.');
    } else {
      setSpaceError('');
    }
    // checking template name has capital letters or not
    const containsCapitalLetter = /[A-Z]/.test(newSegmentData.segmentName);
    setHasCapitalLetter(containsCapitalLetter);

    // checking template name already exists or not
    const nameExists = segmentArray.some(item => item.segmentName === e.target.value);
    if (nameExists) {
      setNameError('Segment name already exists');
    } else {
      setNameError('');
    }
    setNewSegmentData({
      ...newSegmentData,
      segmentName: e.target.value,
    });
  };

  // H A N D L E   D E S C R I P T I O N
  const handleDescriptionChange = (e) => {
    setNewSegmentData({
      ...newSegmentData,
      description: e.target.value,
    });
  };

  // H A N D L E   C R I T E R I A 
  const handleCriteriaChange = (index, e) => {
    const { name, value } = e.target;
    const updatedCriteria = newSegmentData.criteria.map((criterion, i) =>
      i === index ? { ...criterion, [name]: value } : criterion
    );

    setNewSegmentData({
      ...newSegmentData,
      criteria: updatedCriteria,
    });
  };

  // A D D   N E W   C R I T E R I A 
  const handleAddCriteria = () => {
    setNewSegmentData({
      ...newSegmentData,
      criteria: [...newSegmentData.criteria, { column: '', value: '', operation: '' }],
    });
  };

  // R E M O V E  A   C R I T E R I A 
  const handleRemoveCriteria = (index) => {
    setNewSegmentData({
      ...newSegmentData,
      criteria: newSegmentData.criteria.filter((_, i) => i !== index),
    });
  };

  // C O N V E R T   K E Y S   A N D   V A L U E S   I N   L O W E R C A S E
  const convertKeysAndValuesToLowerCase = (obj) => {
    if (obj === null || typeof obj !== 'object') {
      // Convert string values to lowercase
      return typeof obj === 'string' ? obj.toLowerCase() : obj;
    }

    if (Array.isArray(obj)) {
      // Recursively apply the function to each item in the array
      return obj.map(convertKeysAndValuesToLowerCase);
    }

    // For objects, convert keys to lowercase and apply recursively to values
    return Object.keys(obj).reduce((acc, key) => {
      const lowerCaseKey = key.toLowerCase();
      acc[lowerCaseKey] = convertKeysAndValuesToLowerCase(obj[key]);
      return acc;
    }, {});
  };


  // S C R O L L   T O   B O T T O M
  useEffect(() => {
    if (endOfListRef.current) {
      endOfListRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [newSegmentData.criteria]);


  //  S E T   M A N D A T O R Y    E R R O R 
  const mandatoryError = !newSegmentData.segmentName || !newSegmentData.description;


  // H A N D L E   S U B M I T
  const handleSubmit = (e) => {
    e.preventDefault();

    // Convert newSegmentData keys and values to lowercase
    const lowerCaseData = convertKeysAndValuesToLowerCase(newSegmentData);
    const jsonData = JSON.stringify(lowerCaseData);
    console.log(jsonData);

    // const jsonData = JSON.stringify(newSegmentData);
    // console.log(jsonData);

    fetch(createSegment_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`, // Add the Authorization header

      },
      body: JSON.stringify(newSegmentData, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${authToken}`, // Add the Authorization header
          'Content-Type': 'application/json' // Set content type if necessary
        }
      })
    }).then(response => {
      if (response.ok) {
        alert("Segment created successfully.")
      } else {
        // Handle different status codes
        let message = `Error: ${response.status} ${response.statusText}`;
        alert(message);
        throw new Error(message);
      }
    });

    setNewSegmentData({
      segmentName: '',
      criteria: [
        { column: '', value: '', operation: '' } // Initialize with empty values
      ],
    });
    closeModal();
  };

  return (
    <div className="segmentFormMain" style={{ width: "100%", height: "100%" }}>
      <div className="newSegmentHeader">
        <div className="templateMessage_cont">
          <span className='templateMessage'>
            {t('newSegment')}
          </span>

        </div>
        <div className="closeBtn_cont" role="button" aria-label="Close" onClick={closeModal}>
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-x crossSvg" viewBox="0 0 16 16">
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
          </svg>
        </div>
      </div>

      <div className="createSegmentMain_cont">
        <div className="segmentNameInput_cont">
          <div className="segmentHeadingInputCont">
            <div className="uploadHeading_cont segmentNameHeading">
              {t('segmentName')} <span className="asterisk">*</span>
            </div>
            <Box
              className='segmentNameBox'
              component="form"
              sx={{
                '& > :not(style)': { width: '100%', background: "#fff" },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                inputRef={inputRef}
                autoFocus
                InputLabelProps={{ style: { display: 'none' } }}
                name="segmentName"
                value={newSegmentData.segmentName} onChange={handleSegmentNameChange} id="outlined-basic" label="" placeholder={t('segmentPlaceholder')} variant="outlined" />
            </Box>
          </div>
          {/* <div className="andOrMain_cont">
            <div className="button-box">
              <div id="ToggleBtn"></div>
              <button type="button" className="toggle-btn" onClick={leftClick}>{t('and')}</button>
              <button type="button" className="toggle-btn" onClick={rightClick}>{t('or')}</button>
            </div>
          </div> */}
          <div className="createSegErrorCont">

            {
              nameError && <div className="spaceErrorCont">
                <p style={{ color: 'red', fontSize: "12px" }}>{nameError}</p>
              </div>
            }
            {
              spaceError && <div className="spaceErrorCont">
                <p style={{ color: 'red', fontSize: "12px" }}>{spaceError}</p>
              </div>
            }
            {
              hasCapitalLetter && <div className="capitalErrorCont">
                <p style={{ color: 'red', fontSize: "12px" }}>{t('capitalNot')}</p>
              </div>
            }
          </div>


          {/* {spaceError && <p style={{ color: 'red', fontSize: "12px" }}>{spaceError}</p>} */}

        </div>

        <div className="segmentDesc_cont">
          <div className="segmentHeadingInputCont">
            <div className="uploadHeading_cont segmentNameHeading">
              Description <span className="asterisk">*</span>
            </div>
            <Box
              className='segmentNameBox'

              component="form"
              sx={{
                '& > :not(style)': { width: '100%', background: "#fff" },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                InputLabelProps={{ style: { display: 'none' } }}
                name="description"
                value={newSegmentData.description} onChange={handleDescriptionChange} id="outlined-basic" label="" placeholder="Write description..." variant="outlined" />
            </Box>
          </div>
        </div>

        <div className="segmentInputsMain_cont">
          {newSegmentData.criteria.map((criterion, index) => (
            <div key={index} className="criteria_row">
              <div className="criteriaRowCont">
                <div className="uploadHeading_cont">
                  {t('filter')}
                </div>
                <div className="segmentInput_cont">
                  <Select
                    className='filterSelect'
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="column"
                    value={criterion.column}
                    onChange={(e) => handleCriteriaChange(index, e)}
                    displayEmpty
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return <em>Select Filter</em>;
                      }
                      return selected.charAt(0).toUpperCase() + selected.slice(1).toLowerCase();
                    }}
                  >
                    {
                      filterItems.map((item) => {
                        return (
                          <MenuItem key={item.id} value={item.value}>{item.name}</MenuItem>
                        )
                      })
                    }
                  </Select>

                  {/* <TextField
                    style={{ width: "100%" }}
                    InputLabelProps={{ style: { display: 'none' } }}
                    name="column"
                    id="outlined-basic" label=""
                    placeholder="Filter"
                    variant="outlined"
                    value={criterion.column}
                    onChange={(e) => handleCriteriaChange(index, e)}
                  /> */}
                </div>
              </div>
              <div className="criteriaRowCont">
                <div className="uploadHeading_cont">
                  {t('value')}
                </div>
                <div className="segmentInput_cont">
                  {/* <Select
                    className='filterSelect'
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name='value'
                    value={criterion.value}
                    onChange={(e) => handleCriteriaChange(index, e)}
                    disabled={!criterion.column}
                    displayEmpty
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return <em>Select Value</em>;
                      }
                      return selected.charAt(0).toUpperCase() + selected.slice(1).toLowerCase();
                    }}
                  >
                    {criterion.column && valueItems[criterion.column]?.map((item) => (
                      <MenuItem key={item.id} value={item.value}>{item.name}</MenuItem>
                    ))}
                  </Select> */}
                  <TextField
                    style={{ width: "100%" }}
                    InputLabelProps={{ style: { display: 'none' } }}
                    name="value"
                    id="outlined-basic" label=""
                    placeholder="Value"
                    variant="outlined"
                    value={criterion.value}
                    onChange={(e) => handleCriteriaChange(index, e)}
                  />
                </div>
              </div>
              <div className="criteriaRowCont">
                <div className="uploadHeading_cont">
                  {t('action')}
                </div>
                <div className="segmentInput_cont">
                  <Select
                    className='filterSelect'
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="operation"
                    value={criterion.operation}
                    onChange={(e) => { handleCriteriaChange(index, e) }}
                    disabled={!criterion.value}
                    displayEmpty
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return <em>Select Action</em>;
                      }
                      return selected.charAt(0).toUpperCase() + selected.slice(1).toLowerCase();
                    }}
                  >
                    {criterion.value && operationItems[criterion.column]?.map((item) => (
                      <MenuItem key={item.id} value={item.value}>{item.name}</MenuItem>
                    ))}
                  </Select>

                  {/* <TextField
                    style={{ width: "100%" }}
                    InputLabelProps={{ style: { display: 'none' } }}
                    name="operation"
                    id="outlined-basic" label=""
                    placeholder="Action"
                    variant="outlined"
                    value={criterion.operation}
                    onChange={(e) => handleCriteriaChange(index, e)}
                  /> */}
                </div>
              </div>
            </div>
          ))}

          <div className="addCriteria_cont">
            <button type="button" className='addCriteriaBtn' onClick={handleAddCriteria}>Add Criteria</button>
          </div>
          <div className="createSegmentBtn_cont">
            {spaceError && <p style={{ color: 'red', fontSize: "12px", marginRight: "10px" }}>{spaceError}</p>}
            {hasCapitalLetter && <p style={{ color: 'red', fontSize: "12px", marginRight: "10px" }}>Capital letters are not allowed.</p>}
            {mandatoryError && (
              <p style={{ color: 'red', fontSize: "12px", }}>Please fill the mandatory fields.</p>
            )}
            <button className='createSegmentBtn' disabled={!newSegmentData.segmentName || !newSegmentData.description || spaceError || hasCapitalLetter || nameError} onClick={handleSubmit}>{t('save')}</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateSegment;
