import React, { useState } from "react";

import TemplateContext from "./TemplateContext";

const TemplateContextProvider = ({ children }) => {

  const [templateArray, setTemplateArray] = useState(null);

  return (
    <TemplateContext.Provider value={{ templateArray, setTemplateArray }}>
      {children}
    </TemplateContext.Provider>
  )

}

export default TemplateContextProvider;