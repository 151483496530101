import React, { useEffect, useState } from "react";
import ProfitChartContext from "./ProfitChartContext";
const ProfitChartContextProvider = ({ children }) => {

  const [profitChartArray, setProfitChartArray] = useState([]);

  // useEffect(() => {
  //   console.log(profitChartArray);
  // })

  return (
    <ProfitChartContext.Provider value={{ profitChartArray, setProfitChartArray }}>
      {children}
    </ProfitChartContext.Provider>
  )

}

export default ProfitChartContextProvider;