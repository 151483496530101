import React, { useContext, useEffect, useRef } from 'react';
import './Main.css';
import SideBar from '../SideBar/SideBar';
import Toolbar from '../Toolbar/Toolbar';
import { Outlet, useNavigate } from 'react-router-dom';
import MarketplaceContext from '../../Context/MarketplaceContext';
import { useTranslation } from 'react-i18next';

const Main = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const authToken = sessionStorage.getItem('authToken');
  const marketplace_URL = `${t("baseUrl")}/zumi/api/marketplaces`;
  const { setMarketplaceArray } = useContext(MarketplaceContext);

  const inactivityTimeoutRef = useRef(null);

  useEffect(() => {
    fetch(marketplace_URL, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setMarketplaceArray(data);
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });
  }, [authToken, marketplace_URL, setMarketplaceArray]);

  useEffect(() => {
    if (!authToken) {
      navigate('/');
      return;
    }

    const handleUserActivity = () => {
      if (inactivityTimeoutRef.current) {
        clearTimeout(inactivityTimeoutRef.current);
      }
      inactivityTimeoutRef.current = setTimeout(() => {
        console.log('Session Timeout.');
        sessionStorage.removeItem('authToken');
        navigate('/');
      }, 900000); // 15 minutes in milliseconds
    };

    const events = ['mousemove', 'keydown', 'scroll', 'click'];
    events.forEach(event => window.addEventListener(event, handleUserActivity));

    handleUserActivity(); // Start the timer

    return () => {
      events.forEach(event => window.removeEventListener(event, handleUserActivity));
      if (inactivityTimeoutRef.current) {
        clearTimeout(inactivityTimeoutRef.current);
      }
    };
  }, [navigate, authToken]);

  if (!authToken) {
    return null;
  }

  return (
    <div id='main_cont'>
      <div className="MaintoolbarCont">
        <Toolbar />
      </div>
      <div className="MainMainCont">
        <div className="MainSideBarCont">
          <SideBar />
        </div>
        <div className="MainContentCont">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Main;
