import React, { useState } from 'react';
import './CreateBroadcast.css';
import Breadcrumbs from '../../Toolbar/Breadcrumbs';
import { TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import DownloadIcon from '@mui/icons-material/Download';
// import Alert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';


const textFieldStyle = {
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      // borderColor: 'rgb(23, 193, 232)',
      borderRadius: "15px",
      // borderWidth: "1.5px",
    },
    '&:hover fieldset': {
      // borderColor: 'black',
      borderRadius: "15px",
    },
    '&.Mui-focused fieldset': {
      // borderColor: 'black',
      borderRadius: "15px",
    },
  },
}

const CreateBroadcast = () => {
  const { t } = useTranslation();
  const authToken = sessionStorage.getItem("authToken");
  const createBroadcast_URL = `${t("baseUrl")}/zumi/api/createbroadcost`
  const [selectedQuoteFile, setSelectedQuoteFile] = useState(null);
  const [selectedContactFile, setSelectedContactFile] = useState(null);
  const [createBroadcastData, setCreateBroadcastData] = useState({
    supplierId: "",
    supplierName: "",
    email: "",
    phone: "",
    address: "",
  });


  // H A N D L E   Q U O T E   F I L E 
  const handleQuoteFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedQuoteFile(file);
  };

  // H A N D L E  C O N T A C T   F I L E 
  const handleContactFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedContactFile(file);
  };

  // H A N D L E   C H A N G E   F U N C T I O N 
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setCreateBroadcastData((prevData) => ({ ...prevData, [name]: value }));
  }


  // S U B M I T   F U N C T I O N 
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    const formData = new FormData();

    // Append form data
    for (const key in createBroadcastData) {
      formData.append(key, createBroadcastData[key]);
    }

    // Append files
    if (selectedQuoteFile) formData.append('uploadQuotation', selectedQuoteFile);
    if (selectedContactFile) formData.append('uploadContact', selectedContactFile);

    // Log FormData for testing
    for (let [key, value] of formData.entries()) {
      if (value instanceof File) {
        console.log(key, value.name); // Display file name instead of file object
      } else {
        console.log(key, value); // Display value of other fields
      }
    }

    fetch(createBroadcast_URL, {
      method: 'POST',
      body: formData,
      headers: {
        'Authorization': `Bearer ${authToken}`, // Add the Authorization header
      }
    }).then(response => {
      if (response.ok) { // Check if the response status is in the range 200-299
        alert("Broadcast successfull.");
      } else {
        // Handle different status codes
        let message = `Error: ${response.status} ${response.statusText}`;
        alert(message);
        throw new Error(message); // Optionally throw an error to stop further processing
      }
    })

    setCreateBroadcastData({
      supplierId: "",
      supplierName: "",
      email: "",
      phone: "",
      address: "",
    })

    setSelectedQuoteFile(null);
    setSelectedContactFile(null);
    // You would typically send `formData` to your API here using fetch or axios
  };


  // H A N D L E   S A M P L E   Q U O T E   F I L E   D O W N L O A D
  const handleQuoteFileDownload = async () => {
    try {
      const response = await fetch(`${t("baseUrl")}/zumi/sampleQuotationFile.csv`,
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${authToken}`, // Add the Authorization header
          },
        }
      );
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'sampleQuotationFile.csv');        // file name
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url); // Clean up
    } catch (error) {
      console.error('Error downloading the file', error);
    }
  };
  // H A N D L E   S A M P L E   C O N T A C T S   F I L E   D O W N L O A D
  const handleContactFileDownload = async () => {
    try {
      const response = await fetch(`${t("baseUrl")}/zumi/sampleContactsFile.csv`,
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${authToken}`, // Add the Authorization header
          },
        }
      );
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'sampleContactsFile.csv');        // file name
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url); // Clean up
    } catch (error) {
      console.error('Error downloading the file', error);
    }
  };


  return (
    <div className='createBroadcastMain_cont'>
      <Breadcrumbs />
      <div className="createBroadcast_cont">

        <div className="createBroadcastRow">
          <div className="createBroadcastInput_cont" style={{ marginRight: "20px" }}>
            <label htmlFor="contactPerson" className="welcomeFormLabel">{t('supplierName')}  <span className="asterisk">*</span></label>
            <TextField
              className='welcomeFormInput'
              InputLabelProps={{ style: { display: 'none' } }}
              inputProps={{ autoComplete: 'off' }} // Disable autocomplete
              type='text'
              name="supplierName"
              id="outlined-basic"
              placeholder="Enter name"
              variant="outlined"
              sx={textFieldStyle}
              value={createBroadcastData.supplierName}
              onChange={handleChange}
            />
          </div>

          <div className="createBroadcastInput_cont">
            <label htmlFor="contactPerson" className="welcomeFormLabel">{t('email')}  <span className="asterisk">*</span></label>
            <TextField
              className='welcomeFormInput'
              InputLabelProps={{ style: { display: 'none' } }}
              inputProps={{ autoComplete: 'off' }} // Disable autocomplete
              type='email'
              name="email"
              id="outlined-basic"
              placeholder="Enter email"
              variant="outlined"
              sx={textFieldStyle}
              value={createBroadcastData.email}
              onChange={handleChange}
            />

          </div>
        </div>
        <div className="createBroadcastRow">
          <div className="createBroadcastInput_cont" style={{ marginRight: "20px" }}>
            <label htmlFor="contactPerson" className="welcomeFormLabel">{t('phone')}  <span className="asterisk">*</span></label>
            <TextField
              className='welcomeFormInput'
              InputLabelProps={{ style: { display: 'none' } }}
              inputProps={{ autoComplete: 'off' }} // Disable autocomplete
              type='number'
              name="phone"
              id="outlined-basic"
              placeholder="Enter phone"
              variant="outlined"
              sx={textFieldStyle}
              value={createBroadcastData.phone}
              onChange={handleChange}
            />
          </div>
          <div className="createBroadcastInput_cont">
            <label htmlFor="contactPerson" className="welcomeFormLabel">{t('companyAddress')}  <span className="asterisk">*</span></label>
            <TextField
              className='welcomeFormInput'
              InputLabelProps={{ style: { display: 'none' } }}
              inputProps={{ autoComplete: 'off' }} // Disable autocomplete
              type='text'
              name="address"
              id="outlined-basic"
              placeholder="Enter address"
              variant="outlined"
              sx={textFieldStyle}
              value={createBroadcastData.address}
              onChange={handleChange}
            />
          </div>
        </div>


        <div className="createBroadcastRow">
          <div className="createBroadcastInput_cont" style={{ marginRight: "20px" }}>
            <label htmlFor="contactPerson" className="welcomeFormLabel">{t('uploadQuote')}  <span className="asterisk">*</span></label>
            <input
              type='file'
              accept=".csv, .xlsx, .xls"
              className='uploadFileBtn'
              name="uploadQuotation"
              id="uploadQuoteBtn"
              onChange={handleQuoteFileChange}
            />
            <div className="createBroadCastFileLabel_cont">
              <label htmlFor="uploadQuoteBtn" className='uploadQuoteLabel'>
                {t('chooseFile')}
              </label>
              <div className="selectedQuoteFile_cont">
                {selectedQuoteFile && <p style={{ color: "green" }}>{selectedQuoteFile.name}</p>}

              </div>
            </div>
          </div>


          <div className="createBroadcastInput_cont">
            <label htmlFor="contactPerson" className="welcomeFormLabel">{t('uploadContact')}  <span className="asterisk">*</span></label>
            <input
              type='file'
              accept=".csv, .xlsx, .xls"
              className='uploadFileBtn'
              name="uploadContact"
              id="uploadContactBtn"
              onChange={handleContactFileChange}
            />
            <div className="createBroadCastFileLabel_cont">
              <label htmlFor="uploadContactBtn" className='uploadQuoteLabel'>
                {t('chooseFile')}
              </label>
              <div className="selectedContactFile_cont">
                {selectedContactFile && <p style={{ color: "green" }}>{selectedContactFile.name}</p>}
              </div>
            </div>
          </div>
        </div>

        <div className="createBroadcastBtn_cont">
          <div className="createBroadcastHelpLinks_cont">
            <div className="helpAnchorCont">
              <Link className='helpAnchor' onClick={handleQuoteFileDownload}>
                {t('downloadQuoteFile')}
                <DownloadIcon className='launchIcon' style={{ width: "18px", height: "18px", marginLeft: "5px" }} />
              </Link>
            </div>
            <div className="helpAnchorCont" style={{ marginRight: "0px" }}>
              <Link className='helpAnchor' onClick={handleContactFileDownload}>
                {t('downloadContactFile')}
                <DownloadIcon className='launchIcon' style={{ width: "18px", height: "18px", marginLeft: "5px" }} />
              </Link>
            </div>
          </div>
          <button className='btnFill' onClick={handleSubmit}>={t('submit')}</button>
        </div>
      </div>
    </div>
  )
}

export default CreateBroadcast;