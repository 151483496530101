import React, { useState } from "react";
import MarketplaceContext from "./MarketplaceContext";
const MarketplaceContextProvider = ({ children }) => {

  const [marketplaceArray, setMarketplaceArray] = useState([]);

  return (
    <MarketplaceContext.Provider value={{ marketplaceArray, setMarketplaceArray }}>
      {children}
    </MarketplaceContext.Provider>
  )

}

export default MarketplaceContextProvider;