import React, { useState } from "react";

import ProfitContext from "./ProfitContext";

const ProfitContextProvider = ({ children }) => {

  const [profitArray, setProfitArray] = useState([]);

  return (
    <ProfitContext.Provider value={{ profitArray, setProfitArray }}>
      {children}
    </ProfitContext.Provider>
  )
}

export default ProfitContextProvider;