import React, { useState, useEffect, useContext } from 'react';
import './Catalogs.css';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../../Toolbar/Breadcrumbs';
import tableStyles from '../../Sales/Revenue/TableStyles';
import LocationSelect from '../../Dashboard/LocationSelect/LocationSelect';
import ContactTableLoader from '../../Loaders/ContactTableLoader/ContactTableLoader';
import DataTable from 'react-data-table-component';
import SelectedMarketplaceContext from '../../../Context/SelectedMarketplaceContext';
const Catalogs = () => {

  const { t } = useTranslation();

  const authToken = sessionStorage.getItem('authToken');
  const catalogs_URL = `${t("baseUrl")}/zumi/api/existingcatalog`;
  const { selectedMarketplace } = useContext(SelectedMarketplaceContext);


  const [catalogsLoader, setCatalogsLoader] = useState(true);
  const [catalogsData, setCatalogsData] = useState([]);

  const columns = [
    {
      name: t("marketplace"),
      selector: row => row.title,
      width: "120px",
    },
    {
      name: t("sku"),
      selector: row => row.sku,
      width: "150px",
    },
    {
      name: t("asin"),
      selector: row => {
        if (row.asin) {
          return row.asin
        }
        else {
          return row.sku
        }
      },
      width: "150px",
    },
    {
      name: t("productInfo"),
      selector: row => row.itemName,
      width: "280px",
    },
    {
      name: t("quantity"),
      selector: row => row.quantity,
      width: "90px",
    },
    {
      name: t("currencyCode"),
      selector: row => row.currencyCode,
      width: "130px",
    },
    {
      name: t("price"),
      selector: row => row.amount,
      width: "90px",
    },


  ];

  useEffect(() => {
    if (authToken) {

      const fetchData = async () => {
        setCatalogsLoader(true);
        try {
          const response = await fetch(catalogs_URL, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${authToken}`, // Add the Authorization header
              'Content-Type': 'application/json' // Set content type if necessary
            }
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          setCatalogsData(result);
        } catch (error) {
          console.log(error);
        } finally {
          setCatalogsLoader(false);
        }

      };
      fetchData();
    }
  }, [selectedMarketplace]);


  return (
    <div className='revenue_main'>
      <Breadcrumbs />
      <div className="locationMain_cont">
        <LocationSelect />
        {/* <div className="dateRangePickerMain_cont" style={{ display: 'flex', gap: '16px' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs} >
            <DatePicker
              label="Start Date"
              value={roughStartDate}
              onChange={handleStartChange}
              maxDate={dayjs()}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="End Date"
              value={roughEndDate}
              onChange={handleEndChange}
              maxDate={dayjs()}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div> */}
      </div>
      {/* <div className="revenueBtn_cont">
        <Link className='btnFill' style={{ marginRight: "20px" }}>Sample Button</Link>
        <Link className="btnFill">Sample Button</Link>
      </div> */}


      <div className="revenueTableMain_cont">
        <div className='revenueTable_cont'>
          {catalogsLoader ? (
            <ContactTableLoader />
          ) : (
            <DataTable
              columns={columns}
              data={catalogsData}
              customStyles={tableStyles}
              pagination
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default Catalogs;