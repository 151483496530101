import React from 'react';
import './App.css';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import PageNotFound from './components/PageNotFound/PageNotFound';
import Welcome from './components/Welcome/Welcome';
import Main from './components/Main/Main';
import WelcomeMainContent from './components/Welcome/WelcomeMainContent/WelcomeMainContent';
import Signup from './components/Welcome/Signup/Signup';
import Login from './components/Welcome/Login/Login';
import Apps from './components/AppsCom/Apps';
import Dashboard from './components/Dashboard/Dashboard';
import Sales from './components/Sales/Sales';
import Products from './components/Products/Products';
import Reseller from './components/Reseller/Reseller';
import Consumer from './components/Consumer/Consumer';
import Shipping from './components/Shipping/Shipping';
import Whatsapp from './components/WhatsApp/Whatsapp';
import Integration from './components/Integration/Integration';

import ConnectAccount from './components/WhatsApp/ConnectAccount/ConnectAccount';
import ConnectStep1 from './components/WhatsApp/ConnectAccount/ConnectStep1/ConnectStep1';
import ConnectStep2 from './components/WhatsApp/ConnectAccount/ConnectStep2/ConnectStep2';
import AfterBroadcast from './components/WhatsApp/CreateTemplate/MessageHistory/MessageHistory';

import ImportContact from './components/Consumer/ImportContact/ImportContact';
import Segment from './components/Consumer/Segment/Segment';

import StartImport from './components/Consumer/ImportContact/StartImport/StartImport';
import SubmitContact from './components/Consumer/ImportContact/StartImport/SubmitContact/SubmitContact';

import HelpGetStart from './components/Consumer/ImportContact/HelpGetStart/HelpGetStart';
import ImportGuide from './components/Consumer/ImportContact/ImportGuide/ImportGuide';
import ContactFAQ from './components/Consumer/ImportContact/ContactFAQ/ContactFAQ';

import Revenue from './components/Sales/Revenue/Revenue';
import Profit from './components/Sales/Profit/Profit';
import BuyBox from './components/Sales/BuyBox/BuyBox';
import Orders from './components/Sales/Orders/Orders';

import Catalogs from './components/Products/Catalogs/Catalogs';
import Analytics from './components/Products/Analytics/Analytics';

import CreateBroadcast from './components/Reseller/CreateBroadcast/CreateBroadcast';
import ViewBroadcast from './components/Reseller/ViewBroadcast/ViewBroadcast';
import KnowledgeBased from './components/WhatsApp/KnowledgeBased/KnowledgeBased';

import SearchProduct from './components/Reseller/SearchProduct/SearchProduct';
import ViewMarketplace from './components/Integration/ViewMarketplace/ViewMarketplace';
import NewMarketplace from './components/Integration/NewMarketplace/NewMarketplace';

function App() {

  const router = createBrowserRouter([
    {
      path: '/',
      element: <Welcome />,
    },
    {
      path: '/signin',
      element: <Login />,
    },
    {
      path: '/signup',
      element: <Signup />,
    },
    // {
    //   path: '/',
    //   element: <Welcome />,
    //   children: [
    //     {
    //       path: '/',
    //       element: <WelcomeMainContent />
    //     },
    //     {
    //       path: '/signin',
    //       element: <Login />
    //     },
    //     {
    //       path: '/signup',
    //       element: <Signup />
    //     }
    //   ],
    //   errorElement: <PageNotFound />
    // },
    {
      path: "/home",
      element: <Main />,
      children: [
        // {
        //   path: '/home/apps',
        //   element: <Apps />,
        // },
        {
          path: '/home/dashboard',
          element: <Dashboard />,
        },
        {
          path: '/home/sales',
          element: <Sales />,
        },
        {
          path: '/home/products',
          element: <Products />,
        },
        {
          path: '/home/reseller',
          element: <Reseller />,
        },
        {
          path: '/home/contacts',
          element: <Consumer />,
        },
        {
          path: '/home/shipping',
          element: <Shipping />,
        },
        {
          path: '/home/whatsapp',
          element: <Whatsapp />,
        },
        {
          path: '/home/integration',
          element: <Integration />,
        },

        //  R O U T E S   F O R   W H A T S A P P
        {
          path: '/home/whatsapp/message-history',
          element: <AfterBroadcast />
        },
        {
          path: '/home/whatsapp/connect-account',
          element: <ConnectAccount />,
        },
        {
          path: '/home/whatsapp/connect-account/connect-step1',
          element: <ConnectStep1 />,
        },
        {
          path: '/home/whatsapp/connect-account/connect-step1/connect-step2',
          element: <ConnectStep2 />,
        },
        {
          path: '/home/whatsapp/knowledge',
          element: <KnowledgeBased />,
        },

        //  R O U T E S   F O R   C O N T A C T
        {
          path: '/home/contacts/segment',
          element: <Segment />,
        },
        {
          path: '/home/contacts/import-contact',
          element: <ImportContact />,
        },
        {
          path: '/home/contacts/import-contact/start-import',
          element: <StartImport />,
        },
        {
          path: '/home/contacts/import-contact/start-import/submit-contact',
          element: <SubmitContact />,
        },
        {
          path: '/home/contacts/import-contact/help',
          element: <HelpGetStart />,
        },
        {
          path: '/home/contacts/import-contact/start-import/import-guide',
          element: <ImportGuide />,
        },
        {
          path: '/home/contacts/import-contact/start-import/FAQs',
          element: <ContactFAQ />,
        },

        // R O U T E S   F O R   S A L E S
        {
          path: '/home/salesrevenue',
          element: <Revenue />,
        },
        {
          path: '/home/salesprofit',
          element: <Profit />,
        },
        {
          path: '/home/salesorders',
          element: <Orders />,
        },
        {
          path: '/home/salesbuybox',
          element: <BuyBox />,
        },

        // R O U T E S   F O R   P R O D U C T S 

        {
          path: '/home/product-catalogs',
          element: <Catalogs />,
        },
        {
          path: '/home/price-analytics',
          element: <Analytics />,
        },

        // R O U T E S   F O R   R E S E L L E R 

        {
          path: '/home/create-broadcast',
          element: <CreateBroadcast />,
        },
        {
          path: '/home/broadcasts',
          element: <ViewBroadcast />,
        },
        {
          path: '/home/search-product',
          element: <SearchProduct />,
        },
        // R O U T E S   F O R   I N T E G R A T I O N

        {
          path: '/home/view-marketplace',
          element: <ViewMarketplace />,
        },
        {
          path: '/home/new-marketplace',
          element: <NewMarketplace />,
        },

      ],

    }
  ]);

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
