import React, { useState } from "react";

import TotalSalesContext from "./TotalSalesContext";

const TotalSalesContextProvider = ({ children }) => {

  const [totalSalesArray, setTotalSalesArray] = useState([]);

  return (
    <TotalSalesContext.Provider value={{ totalSalesArray, setTotalSalesArray }}>
      {children}
    </TotalSalesContext.Provider>
  )
}

export default TotalSalesContextProvider;