import React, { useState } from "react";

import TokenContext from "./TokenContext";

const TokenContextProvider = ({ children }) => {

  const [tokenFromContext, setTokenFromContext] = useState(null);

  return (
    <TokenContext.Provider value={{ tokenFromContext, setTokenFromContext }}>
      {children}
    </TokenContext.Provider>
  )
}

export default TokenContextProvider;