import React from 'react';
import './ImportGuide.css'
import Breadcrumbs from '../../../Toolbar/Breadcrumbs';

const ImportGuide = () => {
  return (
    <div className='importGuideMain_cont'>
      <Breadcrumbs />
      <div className="importGuide_cont">
        Import Guide
      </div>
    </div>
  )
}

export default ImportGuide;