import React, { useState, useContext, useEffect } from 'react';
import './ViewMarketplace.css';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../../Toolbar/Breadcrumbs';
import tableStyles from '../../Sales/Revenue/TableStyles';
import ContactTableLoader from '../../Loaders/ContactTableLoader/ContactTableLoader';
import DataTable from 'react-data-table-component';
import MarketplaceContext from '../../../Context/MarketplaceContext';

const ViewMarketplace = () => {

  const { t } = useTranslation();
  // const [marketplaceLoader, setMarketplaceLoader] = useState(true);
  const { marketplaceArray } = useContext(MarketplaceContext);
  const columns = [
    {
      name: t('accountName'),
      selector: row => row.nickname,
      // width: "60px",
    },
    {
      name: t('sellerId'),
      selector: row => row.sellerId,
      // width: "170px",
    },
    {
      name: t('marketplace'),
      selector: row => row.title,
      width: "180px",
    },
    {
      name: t('status'),
      selector: row => row.status,
      width: "100px",
    },
    {
      name: t('lastUpdated'),
      selector: row => row.lastsyncdate,
      // width: "170px",
    },
    {
      name: t('action'),
      selector: row => row.createddate,
      width: "100px",
    }
  ];


  return (
    <div className='viewMarketMain_cont'>
      <Breadcrumbs />
      <div className="marketplaceTableMain_cont">
        <div className='revenueTable_cont'>
          <DataTable
            columns={columns}
            data={marketplaceArray}
            customStyles={tableStyles}
            pagination
          />
        </div>
      </div>
    </div>
  )
}

export default ViewMarketplace;